import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/templates';
import React from 'react';
import { useSelector } from 'react-redux';
import columns from 'utils/columns/templates';
import Show from './Show';

const Component = props => {

    const entityType = useSelector(state => state.drawerView.entity);
    const templates = useSelector(state => state.templates);
    const stateProps = {...templates, entityType};

    const actionProps = ({
        changeSearch: actions.useChangeSearch(),
        changeSort: actions.useChangeSort(),
        fetchData: actions.useFetchAll(),
        toggleAll: actions.useToggleAll(),
        toggleOne: event => {
            event.stopPropagation();
            return actions.useToggleOne()(event.target.name);
        }
    });

    return (
        <EntitiesView
            columns={columns}
            showComponent={Show}
            {...stateProps}
            {...actionProps}
            {...props}
        />
    );
};

export default Component;

