import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import Table from 'Components/SimpleTable';
import ShowReading from 'Pages/Readings/Show';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import findWhere from 'selectors/findWhere';
import sortMeasurements from 'utils/sortMeasurements';

const columns = [
    { label: 'ID', dataKey: 'id' },
    { label: 'Name', dataKey: 'readingName' },
    { label: 'Connected Port', dataKey: 'port_name' }
];

const Component = props => {

    const allMeasurements = useSelector(state => state.measurements.models);
    const measurements = useSelector(state => findWhere(state.measurements.ids.map(id => state.measurements.models[id]), { device_id: props.deviceId }));

    const [currentDrawer, setDrawer] = useState('');
    const [measurementToEdit, setMeasurementToEdit] = useState(null);

    const onClose = () => setDrawer('');

    const onShowReading = measurement => event => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        setMeasurementToEdit(measurement);
        setDrawer('showReading');
    };

    const measurementsByConnectedTo = {};
    Object.values(allMeasurements).forEach(measurement => {
        if (measurement.port_id) {
            measurementsByConnectedTo[measurement.port_id] = measurement.name;
        }
    });

    const rows = measurements.map(measurement => {
        const row = {};

        row.id = measurement.id;
        row.name = measurement.name;
        row.readingName = (
            <a href="#" onClick={onShowReading(measurement)}>{measurement.name}</a>
        );
        row.port_name = measurementsByConnectedTo[measurement.id];

        return row;
    });

    let drawer;
    if (currentDrawer === 'showReading') {
        drawer = (
            <ShowReading disableButtons={true} id={measurementToEdit.reading_id}/>
        );
    }

    return (
        <Panel header="Available Measurements">
            <Table columns={columns} rows={rows.sort(sortMeasurements)}/>
            <Drawer onClose={onClose} open={!!currentDrawer}>
                {drawer}
            </Drawer>
        </Panel>
    );
};

export default Component;
