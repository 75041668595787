import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Icon from 'Components/Icon';
import { toggleBuildings } from 'ducks/ui';
import { organizationBuildings } from 'selectors/buildings';
import icons from 'utils/ui/icons';
import { PANEL_HEADER, SPACER_MINI } from 'utils/ui/constants';
import theme from '~/theme.js';

const BuildingsCollapsed = styled('div')({
    width: '100%',
    height: PANEL_HEADER + SPACER_MINI
});

const BuildingsExpanded = styled('div')({
    width: '100%'
});

const StyledAccordion = styled(Accordion)({
    '&:first-child': { borderRadius: 0 },
    '&:last-child': { borderRadius: 0 }
});

const StyledAccordionDetails = styled(AccordionDetails)({
    height: 'calc(100vh - 96px)',
    overflow: 'auto',
    padding: 0,
    width: '100%'
});

const StyledAccordionSummary = styled(AccordionSummary)({
    height: PANEL_HEADER,
    minHeight: PANEL_HEADER,
    padding: '0 12px 0 16px'
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: theme.spacing(1),
    fontSize: theme.spacing(2),
    color: alpha(theme.palette.primary.dark, 0.8)
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
}))

const StyledLink = styled(Link)({
    textDecoration: 'none',
    cursor: 'pointer'
});

const StyledListItem = styled(ListItem)({
    borderBottom: '1px solid #DDDDDD',
    ':hover': {
        backgroundColor: theme.palette.primary.light,
    },
    '&:nth-of-type(2n + 1)': {
        background: theme.palette.backgrounds.color.light,
        ':hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    '&:first-child': {
        borderTop: '1px solid #DDDDDD'
    }
});

const StyledListText = styled(ListItemText)({
    '& .MuiListItemText-primary': {
        fontSize: '0.65rem',
    }
});

const Component = ({ buildingId }) => {
    const building = useSelector(state => state.buildings.models[buildingId]);
    const buildings = useSelector(state => organizationBuildings(state, { buildingId }));
    const panelOpen = useSelector(state => state.ui.showBuildings);

    const dispatch = useDispatch();
    const handleToggle = () => dispatch(toggleBuildings());

    const Wrapper = panelOpen ? BuildingsExpanded : BuildingsCollapsed;

    return (
        <Wrapper>
            <StyledAccordion disableGutters expanded={panelOpen} onChange={handleToggle}>
                <StyledAccordionSummary expandIcon={
                    <StyledIconButton>
                        <Icon icon={icons.expand} size="xs"/>
                    </StyledIconButton>
                }>
                    <StyledTypography>{building.name}</StyledTypography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <List dense component="nav">
                        {buildings.map(building => (
                            <StyledListItem key={building.id}>
                                <StyledLink onClick={handleToggle} to={`/buildings/${building.id}`}>
                                    <ListItem button>
                                        <StyledListText primary={building.name} />
                                    </ListItem>
                                </StyledLink>
                            </StyledListItem>
                        ))}
                    </List>
                </StyledAccordionDetails>
            </StyledAccordion>
        </Wrapper>
    );
};

Component.propTypes = {
    buildingId: PropTypes.string.isRequired
};

export default Component;
