import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions } from 'ducks/installationZones';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { installationZone } = props;
    const { onSave } = props;

    const submitting = useSelector( state => state.installationZones.submitting);

    const formSchema = [
        {
            label: 'Name\x2a',
            name: 'name',
            type: 'text',
            validate: [validators.required]
        },
        {
            type: 'textArea',
            label: 'Description',
            name: 'description',
            rows: 8
        }
    ];

    const dispatch = useDispatch();
    const onSubmit = values => {
        const data = serializeForm(values, formSchema);
        dispatch(actions.update(installationZone.id, data, onSave));
    };

    return (
        <FormViewer label="Edit Installation Zone">
            <Form
                formSchema={formSchema}
                formInitialValues={installationZone}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;
