import IconButton from 'Components/Buttons/IconButton';
import Circle from 'Components/Circle';
import Panel from 'Components/Panel';
import SimpleList from 'Components/SimpleList';
import { actions as deviceActions } from 'ducks/devices';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import icons from 'utils/ui/icons';

const copyToClipboard = str => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        return navigator.clipboard.writeText(str);
    }

    return Promise.reject('The Clipboard API is not available.');
};

const generateUrl = (manageable, device) => {
    let link = 'unavailable';
    let color = '#B94A48';
    if (manageable) {
        link = <a href={`https://${device.balena_uuid}.balena-devices.com`} rel="noreferrer" target="_blank">{device.balena_uuid}</a>;
        color = '#2C6700';
    }

    return (
        <>
            <Circle color={color}/>
            &nbsp;
            {link}
        </>
    );
};

const WebPassword = props => {
    const { password } = props;

    const [copied, setCopied] = useState(false);

    const copyPassword = () => {
        copyToClipboard(password);
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
    };

    return (
        <>
            {password}
            &nbsp;
            <IconButton
                onClick={copyPassword}
                icon={icons.clipboard}
                size="lg"
                style={{ marginLeft: -1, marginTop: -2, padding: 5 }}
                tooltip="click to copy"
            />
            {copied ? ' copied to clipboard!' : null}
        </>
    );
};

const state = (balenaStatus) => {
    let message = 'unknown';
    let color = '#888888';
    if (balenaStatus) {
        message = balenaStatus['state'];
        if (balenaStatus['state'] === 'Online') {
            color = '#2C6700';
        } else if (balenaStatus['state'] === 'Offline') {
            color = '#B94A48';
        } else {
            color = '#E59400';
        }
    }

    return (
        <>
            <Circle color={color}/>
            &nbsp;
            {message}
        </>
    );
};

const isVpnActivated = balenaStatus => balenaStatus ? balenaStatus['vpn_variable'] : false;

const BalenaDetails = props => {
    const { balenaStatus, device, submitting } = props;

    const isManageable = balenaStatus?.manageable;

    const active = isVpnActivated(balenaStatus);

    const dispatch = useDispatch();
    const { getBalenaStatus, toggleVpnOff, toggleVpnOn } = ({
        getBalenaStatus: id => dispatch(deviceActions.getBalenaStatus(id)),
        toggleVpnOff: id => dispatch(deviceActions.toggleVpnOff(id)),
        toggleVpnOn: id => dispatch(deviceActions.toggleVpnOn(id))
    });

    const VpnSection = (
        <>
            <IconButton
                disabled={active !== isManageable || submitting}
                icon={icons[active ? 'off' : 'on']}
                onClick={() => {
                    active ? toggleVpnOff(device.id) : toggleVpnOn(device.id);
                }}
                style={{ marginLeft: -3, padding: 3 }}
                tooltip={`Toggle ${active ? 'Off' : 'On'}`}
            />
            {submitting ? 'processing' : null}
        </>
    );

    const buttons = [
        {
            icon: icons.refresh,
            onClick: () => getBalenaStatus(device.id),
            submitting: submitting,
            tooltip: 'Refresh balena status'
        }
    ];

    const balenaAttributes = [
        { label: 'VPN (change might take up to 15 minutes to take effect)', content: VpnSection }
    ];

    if (!submitting) {
        balenaAttributes.push(
            { label: 'State', content: state(balenaStatus) },
            { label: 'Web Password', content: <WebPassword password={device.web_password}/> },
            { label: 'URL', content: generateUrl(isManageable, device) }
        );
    }

    return (
        <Panel buttons={buttons}>
            <SimpleList content={balenaAttributes}/>
        </Panel>
    );
};

export default BalenaDetails;
