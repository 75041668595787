import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, List } from '@mui/material';
import Icon from 'Components/Icon';
import Tree from 'Components/Tree';
import { expandTree, toggleNode } from 'ducks/ui';
import sortDevices from 'utils/sortDevices';
import statusToColor from 'utils/statusToColor';
import history from '~/history';
import Toolbar from './Toolbar';
import { SPACER_MINI, TREE_HEADER_HEIGHT } from 'utils/ui/constants';

const TreeCollapsed = styled('div')({
    marginTop: 0,
    width: '100%',
    height: TREE_HEADER_HEIGHT + SPACER_MINI
});

const TreeExpanded = styled('div')({
    marginTop: 0,
    width: '100%'
});

const StyledAccordionDetails = styled(AccordionDetails)({
    borderTop: '1px solid #DDDDDD',
    height: 'calc(100vh - 140px)',
    overflow: 'auto',
    padding: 0
});

const StyledAccordion = styled(Accordion)({
    '&:first-child': { borderRadius: 0 },
    '&:last-child': { borderRadius: 0 }
});

const StyledAccordionSummary = styled(AccordionSummary)({
    height: 36,
    margin: 0,
    padding: 0,
    minHeight: 36,
    '&.Mui-expanded': {
        minHeight: 36,
        margin: 0,
        padding: 0
    }
});

const StyledList = styled(List)({
    width: '100%'
});

const getPrimaryText = (device) => <span style={{fontSize: 13}}>{device.name}</span>;

const getSecondaryText = (device) => {
    let text = device.equipment_name;
    const metadataMap = {
        id: 'OneWire',
        ip: 'IP',
        'mbus address': 'MBus',
        'modbus id': 'Modbus'
    };

    for (const [key, label] of Object.entries(metadataMap)) {
        const metadata = device.metadata_values.find(mv => mv.metadata_name.toLowerCase() === key);
        if (metadata) {
            text = `${label} ${metadata.value} (${text})`;
            break;
        }
    }

    return (
        <>
            <Icon icon={['fas', 'circle']} size="xs" color={statusToColor(device.status)} style={{ marginRight: 5 }}/>
            <b style={{fontSize: 12}}>{text}</b>
        </>
    );
};

const reducer = (deviceIds, devices) => {
    return deviceIds
    .map(id => devices[id])
    .filter(device => device.parent_id === null || devices[device.parent_id]?.status === 'disabled' || device.status !== 'disabled')
    .sort(sortDevices)
    .map(device => device.id);
};

const Component = ({ buildingId, deviceId }) => {
    const byParentId = useSelector(state => state.devices.byParentId);
    const devices = useSelector(state => state.devices.models);
    const openNodes = useSelector(state => state.ui.openNodes);
    const panelOpen = useSelector(state => state.ui.showBuildings);

    const rootDeviceIds = (byParentId[null] || []).reduce((acc, id) => {
        const device = devices[id] || {};
        if (device.building_id === buildingId) {
            acc.push(device.id);
        }
        return acc;
    }, []);

    const dispatch = useDispatch();
    useEffect(() => {
        if (deviceId) {
            dispatch(expandTree(deviceId, devices));
        }
    }, [deviceId, dispatch, devices]);

    const trees = reducer(rootDeviceIds, devices).map(rootDeviceId => (
        <Tree
            key={rootDeviceId}
            activeDeviceId={deviceId}
            getPrimaryText={getPrimaryText}
            getSecondaryText={getSecondaryText}
            indexedNodes={byParentId}
            nodeId={rootDeviceId}
            nodes={devices}
            openNodes={openNodes}
            selectNode={deviceId => history.push(`/devices/${deviceId}`)}
            sorter={reducer}
            toggleNode={(id) => dispatch(toggleNode(id))}
        />
    ));

    return (
        <>
            {panelOpen ? <TreeCollapsed /> : <TreeExpanded />}
            <StyledAccordion disableGutters expanded={!panelOpen}>
                <StyledAccordionSummary>
                    <Toolbar buildingId={buildingId} />
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <StyledList dense disablePadding>
                        {trees}
                    </StyledList>
                </StyledAccordionDetails>
            </StyledAccordion>
        </>
    );
};

Component.propTypes = {
    buildingId: PropTypes.string.isRequired,
    deviceId: PropTypes.string
};

export default Component;
