import Table from 'Components/SimpleTable';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styleTime from 'utils/styleTime';

const
    collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }),
    transformLastAcquisition = ({ timestamp }) => styleTime(timestamp);

const columns = [
    { label: 'Name', dataKey: 'name' },
    { label: 'Value', dataKey: 'value' },
    { label: 'Timestamp', dataKey: 'timestamp', transform: transformLastAcquisition }
];

const Component = props => {

    const loading = useSelector(state => state.logs.loading);
    const rows = useSelector(state => state.logs.realtime);



    let sortedRows = rows.sort((a, b) => collator.compare(a.name, b.name));

    return (
        <Table columns={columns} loading={loading} rows={sortedRows}/>
    );
};

export default Component;
