import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, Input, styled } from '@mui/material';

const StyledFormControl = styled(FormControl)({
    width: '100%',
    marginTop: 16,
});

const StyledInput = styled(Input)({
    marginTop: 16,
    marginBottom: 8,
    width: '100%',
    fontSize: "0.65rem"
});

const StyledInputLabel = styled(InputLabel)({
    transform: 'translate(12px, -15px) scale(0.75)',
});

const FileInputComponent = ({ accept, disabled, input, label, meta }) => {
    return (
        <StyledFormControl
            disabled={disabled}
            error={meta.touched && !!meta.error}
            variant="filled"
        >
            <StyledInputLabel shrink>{label}</StyledInputLabel>
            <StyledInput
                inputProps={{ accept }}
                onChange={(event) => input.onChange(event.target.files[0])}
                type="file"
            />
            {meta.touched && !!meta.error && (
                <FormHelperText error>{meta.error}</FormHelperText>
            )}
        </StyledFormControl>
    );
};

FileInputComponent.propTypes = {
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    input: PropTypes.object.isRequired, // Injected by Redux Forms
    label: PropTypes.string,
    meta: PropTypes.object, // Injected by Redux Forms
};

export default FileInputComponent;
