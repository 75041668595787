import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as indicatorsActions } from 'ducks/indicators';
import { loadBuilding } from 'ducks/loadBuilding';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const formSchema = [
    {
        type: 'text',
        label: 'Name\x2a',
        name: 'name',
        validate: [validators.required]
    },
    {
        type: 'textArea',
        label: 'Description',
        name: 'description'
    },
    {
        type: 'text',
        label: 'Max value\x2a',
        name: 'max_value',
        validate: [validators.required]
    },
    {
        type: 'text',
        label: 'Min value\x2a',
        name: 'min_value',
        validate: [validators.required]
    }
];

const Component = props => {
    const { device, measurement } = props;

    const indicatorKinds = useSelector(state => state.bootstrap.indicator_kinds);
    const submitting = useSelector(state => state.indicators.submitting);

    const indicatorKind = indicatorKinds.find(kind => kind.id === measurement.indicator_kind_id);

    const formInitialValues = {
        max_value: indicatorKind.max_value,
        min_value: indicatorKind.min_value,
        name: measurement.name
    };

    const dispatch = useDispatch();
    const onSubmit = values => {
        const data = {
            ...serializeForm(values, formSchema),
            ...{ measurement_id: measurement.id, type: 'raw' }
        };
        dispatch(indicatorsActions.create(
            data,
            () => dispatch(loadBuilding(device.building_id))
        ));
    };

    return (
        <FormViewer label={'Indicator Form'}>
            <Form
                formSchema={formSchema}
                formInitialValues={formInitialValues}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;
