import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { styled, alpha } from '@mui/material/styles';

import ConfirmationBox from 'Components/ConfirmationBox';
import Icon from 'Components/Icon';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: theme.spacing(1),
    fontSize: theme.spacing(1),
    color: alpha(theme.palette.primary.dark, 0.75),
}));

const IconButtonWithConfirmation = ({
                                        confirmationText,
                                        confirmationHeader = 'Please Confirm',
                                        submitting = false,
                                        color,
                                        disabled,
                                        icon,
                                        spin,
                                        size = 'medium',
                                        style,
                                        tooltip,
                                        onClick,
                                    }) => {
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (isSubmitting && !submitting) {
            setIsSubmitting(false);
            setOpenConfirmation(false);
        } else if (!isSubmitting && submitting) {
            setIsSubmitting(true);
        }
    }, [submitting]);

    const handleOpenDialog = () => setOpenConfirmation(true);
    const handleCloseDialog = () => setOpenConfirmation(false);

    const iconButton = (
        <StyledIconButton
            disabled={disabled}
            onClick={confirmationText ? handleOpenDialog : onClick}
            style={style}
        >
            <Icon icon={icon} fontSize={size} spin={spin} fixedWidth style={{ color: color ?? null }} />
        </StyledIconButton>
    );

    return (
        <>
            {confirmationText && (
                <ConfirmationBox
                    alert={confirmationText}
                    alertHeader={confirmationHeader}
                    open={openConfirmation}
                    onConfirm={onClick}
                    onNo={handleCloseDialog}
                    submitting={submitting}
                />
            )}
            {tooltip && !disabled ? <Tooltip title={tooltip}>{iconButton}</Tooltip> : iconButton}
        </>
    );
};

IconButtonWithConfirmation.propTypes = {
    icon: PropTypes.arrayOf(PropTypes.string),
    confirmationHeader: PropTypes.string,
    confirmationText: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
    spin: PropTypes.bool,
    tooltip: PropTypes.string,
    submitting: PropTypes.bool,
    disabled: PropTypes.bool,
    style: PropTypes.object,
};

export default IconButtonWithConfirmation;
