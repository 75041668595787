import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as measurementActions } from 'ducks/measurements';
import React from 'react';
import { useSelector } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields, serializeForm } from 'utils/forms/utils';

const Component = props => {
    const { device, readingId } = props;
    const { closeDrawer } = props;

    const metadatas = useSelector(state => selectorMetadatas.metadataFromMeasurement(state, props));
    const submitting = useSelector(state => state.measurements.submitting);

    const formSchema = [
        {
            type: 'text',
            label: 'Name\x2a',
            name: 'name'
        },
        ...metadataFields(metadatas)
    ];

    const create = measurementActions.useCreate();
    const onSubmit = formValues => {
        let data = {
            ...serializeForm(formValues, formSchema),
            device_id: device.id,
            reading_id: readingId
        };

        create(data, closeDrawer);
    };

    return (
        <FormViewer label="Add Measurement">
            <Form
                formSchema={formSchema}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;
