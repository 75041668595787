import React from 'react';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import findWhere from 'selectors/findWhere';
import Accesses from 'Pages/Tree/Device/Accesses';
import Children from 'Pages/Tree/Device/Children';
import Data from 'Pages/Tree/Device/Data';
import Deploys from 'Pages/Tree/Device/Deploys';
import Indicators from 'Pages/Tree/Device/Indicators';
import InstallationPictures from 'Pages/Tree/Device/InstallationPictures';
import LogsTable from 'Pages/Tree/Device/Logs/Table';
import Readings from 'Pages/Tree/Device/Readings';
import Realtime from 'Pages/Tree/Device/Realtime';
import Details from './Details';
import Toolbar from './Toolbar';
import { HEADER_HEIGHT, SPACER_MINI, TABS_HEIGHT } from 'utils/ui/constants';

const MainContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.backgrounds.color.main,
    padding: '0px 10px 10px',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
}));

const HeaderGrid = styled(Grid)({
    height: `${TABS_HEIGHT + SPACER_MINI}px`,
    width: '100%',
});

const BottomGrid = styled(Grid)({
    height: `calc(100% - ${TABS_HEIGHT + SPACER_MINI}px)`,
    overflowY: 'auto',
    width: '100%',
});

const Component = ({ deviceId, section, match }) => {
    const childDevices = useSelector(state => findWhere(state.devices.ids.map(id => state.devices.models[id]), { parent_id: deviceId }));
    const device = useSelector(state => state.devices.models[deviceId]);
    const indicators = useSelector(state => state.indicators.models);
    const installationZones = useSelector(state => state.installationZones.models);
    const measurements = useSelector(state => findWhere(state.measurements.ids.map(id => state.measurements.models[id]), { device_id: deviceId }));

    const buildingInstallationZones = findWhere(Object.keys(installationZones).map(id => installationZones[id]), { building_id: device?.building_id });

    if (!section) {
        if (device?.new_wise_box) {
            section = 'details';
        } else if (Array.isArray(measurements) && measurements.length) {
            section = 'measurements';
        } else if (childDevices && !isEmpty(childDevices)) {
            section = 'devices';
        } else {
            section = 'details';
        }
    }

    let Section;
    switch (section) {
        case 'accesses':
            Section = <Accesses device={device} />;
            break;
        case 'data':
            Section = <Data device={device} />;
            break;
        case 'devices':
            Section = <Children childDevices={childDevices} device={device} installationZones={buildingInstallationZones} />;
            break;
        case 'indicators':
            Section = <Indicators device={device} indicators={indicators} match={match} measurements={measurements} />;
            break;
        case 'logs':
            Section = <LogsTable device={device} />;
            break;
        case 'measurements':
            Section = <Readings device={device} />;
            break;
        case 'pictures':
            Section = <InstallationPictures device={device} />;
            break;
        case 'realtime':
            Section = <Realtime device={device} />;
            break;
        case 'deploys':
            Section = <Deploys device={device} />;
            break;
        default:
            Section = <Details childDevices={childDevices} device={device} installationZones={buildingInstallationZones} measurements={measurements} />;
    }

    return (
        <MainContainer container>
            <HeaderGrid>
                <Toolbar currentTab={section} device={device} />
            </HeaderGrid>
            <BottomGrid>
                {Section}
            </BottomGrid>
        </MainContainer>
    );
};

export default Component;
