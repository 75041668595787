import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import TabsToolbar from 'Components/TabsToolbar';
import PropTypes from 'prop-types';
import React from 'react';
import icons from 'utils/ui/icons';
import { HEADER_HEIGHT, SPACER_MINI, TABS_HEIGHT } from 'utils/ui/constants';

const MainContainer = styled(Grid)(({ theme }) => ({
    width: '100%',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    backgroundColor: theme.palette.backgrounds.color.main,
    padding: '0px 10px 0px'
}));

const HeaderContainer = styled(Grid)(() => ({
    height: `${TABS_HEIGHT + SPACER_MINI}px`
}));

const BottomContainer = styled(Grid)(() => ({
    height: `calc(100% - ${TABS_HEIGHT + SPACER_MINI + SPACER_MINI}px)`,
    overflowY: 'auto'
}));

const StyledCard = styled(Card)(() => ({
    borderRadius: 0
}));

const StyledCardContent = styled(CardContent)(() => ({
    overflow: 'auto',
    padding: 12
}));

const Component = ({ label, onBack, children }) => {
    const buttons = onBack ? [{ icon: icons.back, onClick: onBack, tooltip: 'Back' }] : [];
    const tabs = label ? [{ text: label, id: 'none' }] : [];

    return (
        <MainContainer container>
            <HeaderContainer size={12}>
                <TabsToolbar buttons={buttons} tabs={tabs} />
            </HeaderContainer>
            <BottomContainer size={12}>
                <StyledCard>
                    <StyledCardContent>
                        {children}
                    </StyledCardContent>
                </StyledCard>
            </BottomContainer>
        </MainContainer>
    );
};

Component.propTypes = {
    label: PropTypes.string.isRequired,
    onBack: PropTypes.func,
    children: PropTypes.node
};

export default Component;
