import EntityView from 'Components/EntityView';
import { actions } from 'ducks/metadata';
import { capitalize } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';

const properties = model => [
    { label: 'ID', content: model.id },
    { label: 'Name', content: model.name },
    { label: 'Type', content: capitalize(model.metadata_type) },
    { label: 'Enumerator members', content: (model.enumerator_members || []).join(', ') },
    { label: 'Creation', content: `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
    { label: 'Update', content: `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const Component = props => {

    const loading = useSelector(state => state.metadatas.loading);
    const model = useSelector(state => state.metadatas.models[state.drawerView.entityId]);
    const root = useSelector(state => state.users.models[state.auth.currentUserId].root);
    const stateProps = { loading, model, root };

    const actionProps = ({
        remove: actions.useRemove()
    });

    return <EntityView
        disableDelete
        disableEdit
        properties={properties}
        tabsHeader={'Metadata details'}
        {...stateProps}
        {...actionProps}
        {...props}
    />;
};

export default Component;
