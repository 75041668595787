import React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Icon from 'Components/Icon';
import icons from 'utils/ui/icons';

const Container = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.default,
    zIndex: theme.zIndex.tooltip + 1,
}));

const Spinner = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}));

const LoaderBar = styled('div')(({ theme }) => ({
    color: theme.palette.primary.light,
    position: 'fixed',
    top: '65%',
    left: '35%',
    width: '30%',
}));

const Loader = () => {
    return (
        <Container>
            <Spinner>
                <Icon icon={icons.circle_notch} size="3x" spin />
            </Spinner>
            <LoaderBar>
                <LinearProgress />
            </LoaderBar>
        </Container>
    );
};

export default Loader;
