import TabsToolbar from 'Components/TabsToolbar';
import { actions as drawerViewActions } from 'ducks/drawerView';
import { actions as readingActions } from 'ducks/readings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icons from 'utils/ui/icons';

const Toolbar = props => {
    const { disableButtons = false, reading } = props;

    const loading = useSelector(state => state.readings.loading);

    const dispatch = useDispatch();
    const { editEntity, previousState, remove } = ({
        editEntity: (entityId, entity) => dispatch(drawerViewActions.editEntity(entityId, entity)),
        previousState: () => dispatch(drawerViewActions.previousState()),
        remove: readingActions.useRemove()
    });

    const
        buttons = [
            { icon: icons.back, onClick: () => previousState(), tooltip: 'Back' },
            { icon: icons.editConfig, onClick: () => editEntity(reading.id, 'READING'), tooltip: 'Edit' },
            {
                icon: icons.trash,
                confirmationHeader: 'Delete Confirmation',
                confirmationText: 'Are you sure you want to delete this reading?',
                submitting: loading,
                onClick: () => remove(reading.id, () => props.onClose()),
                tooltip: 'Delete'
            }
        ],
        tabs = [
            { text: 'reading details', id: 'properties' },
            { text: 'Measurements', id: 'measurements' }
        ];

    return (
        <TabsToolbar buttons={disableButtons ? [] : buttons} tabs={tabs}/>
    );
};

export default Toolbar;
