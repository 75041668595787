import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as deviceActions } from 'ducks/devices';
import { actions as wiseBoxActions } from 'ducks/wise_boxes';
import { sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectorForm from 'selectors/form';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { onClose } = props;

    const availableDevices = useSelector(state => state.devices.available);
    const buildingModels = useSelector(state => state.buildings.models);
    const formValues = useSelector(state => selectorForm.getFormValues(state));
    const organizations = useSelector(state => state.auth.organizations);
    const submitting = useSelector(state => state.wise_boxes.submitting);
    const wise_box = useSelector(state => state.wise_boxes.models[state.drawerView.entityId]);
    const loading = useSelector(state => state.devices.loading);

    const dispatch = useDispatch();
    const claim = (WiseboxID, data, callback) => dispatch(wiseBoxActions.claim(WiseboxID, data, callback));

    useEffect(() => {
        dispatch(deviceActions.fetchAvailable());
    }, [dispatch]);

    let buildings = Object.values(buildingModels);

    let devicesOrganizationIds = [...new Set(availableDevices.map(b => b.organization_id))];

    const availableOrganizations = organizations.filter(organization => {
        return organization.disabled === false &&
            devicesOrganizationIds.includes(organization.id);
    });

    const formSchema = [
        {
            disabled: !!formValues.building_id || loading,
            formData: sortBy(availableOrganizations, 'name'),
            label: 'Organization\x2a',
            name: 'organization_id',
            type: 'select',
            validate: [validators.required]
        }
    ];

    const getName = device => {
        return (
            buildings.find(building => building.building_id === device.building_id).name + ' > ' + device.name
        );
    };

    if (formValues.organization_id) {
        const deviceData = availableDevices
        .filter(device => device.organization_id === formValues.organization_id)
        .map((device) => ({
            id: device.id,
            name: getName(device)
        }));

        formSchema.push({
            type: 'select',
            label: 'Root Device\x2a',
            name: 'device_id',
            validate: [validators.required],
            formData: sortBy(deviceData, 'name')
        });
    }

    const onSubmit = values => {
        const data = serializeForm(values, formSchema);
        claim(wise_box.id, data, () => onClose());
    };

    return (
        <FormViewer label={'Claim WiseBox'}>
            <Form
                formSchema={formSchema}
                isSubmitting={submitting || loading}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;
