import React from 'react';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import ExpandedPanel from 'Components/ExpandedPanel';
import SimpleList from 'Components/SimpleList';
import TabsToolbar from 'Components/TabsToolbar';
import { actions } from 'ducks/installationPictures';
import { useSelector } from 'react-redux';
import { unixToDate } from 'utils/date';
import getReadableFileSize from 'utils/getReadableFileSize';
import getUserName from 'utils/getUserName';
import icons from 'utils/ui/icons';
import { HEADER_HEIGHT, SPACER_MINI, TABS_HEIGHT } from 'utils/ui/constants';

const MainContainer = styled(Grid)(({ theme }) => ({
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    backgroundColor: theme.palette.backgrounds.color.main,
    padding: '0px 10px 0px',
}));

const HeaderContainer = styled(Grid)(({ theme }) => ({
    height: `${TABS_HEIGHT + SPACER_MINI}px`,
}));

const BottomContainer = styled(Grid)(({ theme }) => ({
    height: `calc(100% - ${TABS_HEIGHT + SPACER_MINI + SPACER_MINI}px)`,
    paddingBottom: 5,
    overflowY: 'auto',
}));

const StyledImage = styled('img')({
    maxWidth: '100%',
    '&:hover': {
        cursor: 'pointer',
    },
});

const Comment = styled('div')({
    fontSize: '0.9rem',
    marginTop: 10,
});

const Component = ({ installationPicture, setOpenDrawer }) => {
    const submitting = useSelector((state) => state.installationPictures.submitting);

    const attributes = [
        { label: 'ID', content: installationPicture.id },
        { label: 'Comment', content: installationPicture.comment },
        {
            label: 'URL',
            content: (
                <a href={installationPicture.url} rel="noreferrer" target="_blank">
                    Open in new window
                </a>
            ),
        },
        { label: 'File Size', content: getReadableFileSize(installationPicture.size) },
        { label: 'Type', content: installationPicture.mime_type },
        {
            label: 'Dimensions',
            content: `${installationPicture.width}x${installationPicture.height} px`,
        },
        {
            label: 'Created',
            content: `${unixToDate(installationPicture.created_at)} by ${getUserName(installationPicture.created_by)}`,
        },
        {
            label: 'Updated',
            content: `${unixToDate(installationPicture.updated_at)} by ${getUserName(installationPicture.updated_by)}`,
        },
    ];

    const remove = actions.useRemove();

    const onEditClick = () =>
        setOpenDrawer({ name: 'EditPicture', props: { installationPicture } });

    const onDelete = () => remove(installationPicture.id, () => setOpenDrawer({}));

    const buttons = [
        { icon: icons.edit, onClick: onEditClick, tooltip: 'Edit Picture' },
        {
            icon: icons.trash,
            confirmationHeader: 'Delete Confirmation',
            confirmationText: 'Are you sure you want to delete this picture?',
            submitting,
            onClick: onDelete,
            tooltip: 'Delete',
        },
    ];

    const tabs = [
        { text: 'Details', id: 'details' },
        { text: 'Picture', id: 'picture' },
    ];

    return (
        <MainContainer item xs={12}>
            <HeaderContainer container>
                <TabsToolbar buttons={buttons} tabs={tabs} />
            </HeaderContainer>
            <BottomContainer xs={12}>
                <ExpandedPanel id="picture" header="Picture">
                    <StyledImage alt={installationPicture.comment} src={installationPicture.url} />
                    {installationPicture.comment && <Comment>{installationPicture.comment}</Comment>}
                </ExpandedPanel>
                <ExpandedPanel id="details" header="Details">
                    <SimpleList content={attributes} />
                </ExpandedPanel>
            </BottomContainer>
        </MainContainer>
    );
};

export default Component;
