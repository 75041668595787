import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, Paper, styled } from '@mui/material';
import Loader from 'Components/ScopedLoader';
import TablePaginationActions from './TablePaginationActions';

const StyledPaper = styled(Paper)(() => ({
    boxShadow: 'none',
    marginTop: 5,
    width: '100%',
}));

const StyledTable = styled(Table)(() => ({
    minWidth: 500
}));

const StyledTableRowHeader = styled(TableRow)(() => ({
    height: '38px'
}));

const StyledTableRow = styled(TableRow)(() => ({
    height: '30px',
    '&:hover': {
        background: '#EEEEEE'
    }
}));

const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    paddingLeft: 5,
    fontSize: '0.80rem',
}));

const StyledTableCell = styled(TableCell)(() => ({
    fontSize: '0.65rem',
    padding: 0,
    paddingLeft: 5
}));

const StyledTableWrapper = styled('div')(() => ({
    overflowX: 'auto'
}));

const Component = ({ columns, loading, onRowClick, rows, rowsPerPage: initialRowsPerPage }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

    if (loading) {
        return <Loader />;
    }

    const Columns = columns.map(({ label }) => (
        <StyledTableCellHeader key={label}>{label}</StyledTableCellHeader>
    ));

    const Rows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
        const onClick = onRowClick ? () => onRowClick(row.id, row) : null;
        return (
            <StyledTableRow key={row.id} onClick={onClick} style={{ cursor: onRowClick ? 'pointer' : 'auto' }}>
                {columns.map(column => {
                    let value = column.transform ? column.transform(row) : row[column.dataKey];
                    return (
                        <StyledTableCell key={column.dataKey}>
                            {value !== undefined && value !== null ? value : ''}
                        </StyledTableCell>
                    );
                })}
            </StyledTableRow>
        );
    });

    return (
        <StyledPaper>
            <StyledTableWrapper>
                <StyledTable>
                    <TableHead>
                        <StyledTableRowHeader>{Columns}</StyledTableRowHeader>
                    </TableHead>
                    <TableBody>{Rows}</TableBody>
                    {rows.length > rowsPerPage && (
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    ActionsComponent={TablePaginationActions}
                                    colSpan={3}
                                    count={rows.length}
                                    onPageChange={(event, newPage) => setPage(newPage)}
                                    onRowsPerPageChange={event => {
                                        setRowsPerPage(parseInt(event.target.value, 10));
                                        setPage(0);
                                    }}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={[rowsPerPage]}
                                    SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                                />
                            </TableRow>
                        </TableFooter>
                    )}
                </StyledTable>
            </StyledTableWrapper>
        </StyledPaper>
    );
};

Component.propTypes = {
    columns: PropTypes.array.isRequired,
    onRowClick: PropTypes.func,
    rows: PropTypes.array.isRequired,
    rowsPerPage: PropTypes.number,
    loading: PropTypes.bool
};

Component.defaultProps = {
    rowsPerPage: 100
};

export default Component;
