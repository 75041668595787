import EntityView from 'Components/EntityView';
import React from 'react';
import { useSelector } from 'react-redux';

const properties = model => [
    { label: 'ID', content: model.id },
    { label: 'Name', content: model.name },
    { label: 'Email', content: model.email },
    { label: 'Enabled?', content: model.enabled ? 'Yes' : 'No' },
    { label: 'Config?', content: model.config ? 'Yes' : 'No' }
];

const Component = props => {

    const loading = useSelector(state => state.users.loading);
    const model = useSelector(state => state.users.models[state.drawerView.entityId]);
    const stateProps = { loading, model };

    return <EntityView
        disableDelete
        properties={properties}
        tabsHeader={'User details'}
        {...stateProps}
        {...props}
    />;
};

export default Component;
