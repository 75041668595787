import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from 'Components/Buttons/IconButton';
import ExpandedPanel from 'Components/ExpandedPanel';
import Panel from 'Components/Panel';
import Loading from 'Components/ScopedLoader';
import SimpleTable from 'Components/SimpleTable';
import TabsToolbar from 'Components/TabsToolbar';

import { actions } from 'ducks/calibrations';
import calibrationsSelector from 'selectors/calibrations';
import getReadableFileSize from 'utils/getReadableFileSize';
import icons from 'utils/ui/icons';

import { HEADER_HEIGHT, SPACER_MINI, TABS_HEIGHT } from 'utils/ui/constants';

const MainContainer = styled(Grid)(({ theme }) => ({
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    backgroundColor: theme.palette.backgrounds.color.main,
    padding: '0px 10px 0px',
}));

const HeaderContainer = styled(Grid)(() => ({
    height: `${TABS_HEIGHT + SPACER_MINI}px`,
}));

const BottomContainer = styled(Grid)(() => ({
    height: `calc(100% - ${TABS_HEIGHT + SPACER_MINI + SPACER_MINI}px)`,
    overflowY: 'auto',
}));

const transformPicture = (calibration) => {
    if (calibration.picture_url) {
        return (
            <>
                {calibration.picture_mime_type} | {getReadableFileSize(calibration.picture_size)} |{' '}
                <a href={calibration.picture_url} rel="noreferrer" target="_blank">
                    view
                </a>
            </>
        );
    }

    return '-';
};

const columns = [
    { label: 'ID', dataKey: 'id' },
    { label: 'Date', dataKey: 'date' },
    { label: 'Value', dataKey: 'value' },
    { label: 'Picture', dataKey: 'picture', transform: transformPicture },
    { label: 'Actions', dataKey: 'actions' },
];

const CalibrationsPage = ({ measurement, createCalibration }) => {
    const calibrations = useSelector((state) => calibrationsSelector(state, measurement.id));
    const loading = useSelector((state) => state.calibrations.loading);
    const submitting = useSelector((state) => state.calibrations.submitting);

    const remove = actions.useRemove();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchAll({ measurement_id: measurement.id }));
    }, [measurement.id, dispatch]);

    if (loading) {
        return (
            <Panel>
                <Loading />
            </Panel>
        );
    }

    const buttons = [
        {
            icon: icons.add,
            onClick: createCalibration,
            tooltip: 'Add Calibration',
        },
    ];

    const tabs = [{ text: 'Calibrations', id: 'calibrations' }];

    const rows = calibrations.map((calibration) => ({
        ...calibration,
        actions: (
            <IconButton
                confirmationText="Are you sure you want to remove this calibration?"
                icon={icons.destroy}
                onClick={() => remove(calibration.id)}
                submitting={submitting}
                tooltip="Remove calibration"
            />
        ),
    }));

    return (
        <MainContainer container>
            <HeaderContainer size={12}>
                <TabsToolbar buttons={buttons} tabs={tabs} />
            </HeaderContainer>
            <BottomContainer size={12}>
                <ExpandedPanel id="calibrations">
                    <SimpleTable columns={columns} rows={rows} />
                </ExpandedPanel>
            </BottomContainer>
        </MainContainer>
    );
};

export default CalibrationsPage;
