import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/users';
import React from 'react';
import columns from 'utils/columns/users';
import Edit from './Edit';
import New from './New';
import Show from './Show';
import { useSelector } from 'react-redux';

const Component = props => {

    const entityType = useSelector(state => state.drawerView.entity);
    const users = useSelector(state => state.users);
    const stateProps = {...users, entityType};

    const { useChangeSearch, useChangeSort, useFetchAll, useToggleAll, useToggleOne } = actions;
    const actionProps = {
        changeSearch: useChangeSearch(),
        changeSort: useChangeSort(),
        fetchData: useFetchAll(),
        toggleAll: useToggleAll(),
        toggleOne: event => {
            event.stopPropagation();
            return useToggleOne()(event.target.name);
        }
    };

    return <EntitiesView
        columns={columns}
        editComponent={Edit}
        newComponent={New}
        showComponent={Show}
        {...stateProps}
        {...actionProps}
        {...props}
    />;
};

export default Component;
