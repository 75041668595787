import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions } from 'ducks/buildings';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const UploadDevices = props => {
    const { buildingId } = props;

    const uploading = useSelector( state => state.buildings.uploadingDevices);

    const formSchema = [
        {
            accept: 'text/csv\n',
            label: 'File\x2a',
            name: 'file',
            type: 'file',
            validate: [validators.required]
        }
    ];

    const dispatch = useDispatch();
    const onSubmit = values => {
        const data = serializeForm(values, formSchema);
        dispatch(actions.uploadDevices(buildingId, data.file));
    };

    return (
        <FormViewer label="Upload Devices File">
            <a href="/static/example.csv" target="_blank">Download example file</a>
            <br/>
            <br/>
            <Form
                buttonLabel="Upload"
                formSchema={formSchema}
                isSubmitting={uploading}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default UploadDevices;
