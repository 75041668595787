import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Loader from 'Components/ScopedLoader';
import { EXPANDED_EXPANSION_PANEL_HEADER_NEW } from 'utils/ui/constants';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    margin: theme.spacing(1, 0),
    borderRadius: 0
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
    height: EXPANDED_EXPANSION_PANEL_HEADER_NEW,
    minHeight: EXPANDED_EXPANSION_PANEL_HEADER_NEW
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme, hasHeader }) => ({
    paddingTop: hasHeader ? 0 : theme.spacing(2)
}));

const StyledTypography = styled(Typography)(() => ({
    fontSize: '0.75rem',
    fontWeight: 'bold'
}));

const Component = ({ header, id, loading, children }) => {
    return (
        <StyledAccordion id={id} expanded>
            {header && (
                <StyledAccordionSummary>
                    <StyledTypography gutterBottom>
                        {header}
                    </StyledTypography>
                </StyledAccordionSummary>
            )}
            <StyledAccordionDetails hasHeader={!!header}>
                <Grid xs={12}>
                    {loading ? <Loader /> : children}
                </Grid>
            </StyledAccordionDetails>
        </StyledAccordion>
    );
};

Component.propTypes = {
    header: PropTypes.string,
    id: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.node
};

export default Component;
