import * as React from 'react';
import { Form as ShadForm } from '~/ComponentsTW/ui/form';
import { Input as ShadInput } from '~/ComponentsTW/ui/input';
import { Label as ShadLabel} from '~/ComponentsTW/ui/label';

const Form = React.forwardRef(({ className, onSubmit, ...props }, ref) => {
    const id = React.useId();

    return (
        <div className="tw-grid tw-gap-4">
            <ShadForm ref={ref} className={className} onSubmit={onSubmit} {...props} id={id} />
        </div>
    );
});
Form.displayName = 'Form';

const FormInput = React.forwardRef(({ className, ...props }, ref) => {
    const id = React.useId();

    return (
        <ShadInput ref={ref} className={`${className} tw-mt-[-8px]`} {...props} id={id} />
    );
});
FormInput.displayName = 'FormInput';

const FormLabel = React.forwardRef(({ className, ...props }, ref) => {
    const id = React.useId();

    return (
        <ShadLabel ref={ref} className={`tw-ml-2 tw-text-lg tw-mb-0 ${className}`} {...props} id={id} />
    );
});
FormLabel.displayName = 'FormLabel';

export {
    Form,
    FormInput,
    FormLabel,
    // WmFormControl,
    // WmFormDescription,
    // WmFormMessage,
    // WmFormField
};
