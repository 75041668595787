import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled, alpha } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import icons from 'utils/ui/icons';

const StyledListItem = styled(ListItem)(({ theme }) => ({
    minHeight: 40,
    paddingTop: 0,
    paddingBottom: 0,
    '&:nth-of-type(2n + 1)': {
        background: '#EDF3FE'
    },
    borderBottom: '1px solid #DDDDDD',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    fontSize: 20,
    color: alpha(theme.palette.primary.dark, 0.75)
}));

const StyledListItemText = styled(ListItemText)({
    paddingLeft: 0,
    paddingRight: 0,
});

const Component = ({ activeDeviceId, getPrimaryText, getSecondaryText, indexedNodes, nodeId, nodes, openNodes, selectNode, sorter, toggleNode, depth = 0 }) => {
    const node = nodes[nodeId];
    if (!node) return null;

    const handleSelect = (event) => selectNode(event.currentTarget.dataset.id);
    const handleToggle = (event) => toggleNode(event.currentTarget.dataset.id);
    const children = indexedNodes[nodeId];

    const Icon = children ? (
        <StyledIconButton onClick={handleToggle} data-id={nodeId}>
            <FontAwesomeIcon fixedWidth icon={icons[openNodes.includes(nodeId) ? 'closeNode' : 'openNode']} size="xs"/>
        </StyledIconButton>
    ) : null;

    const Children = children ? (
        <Collapse in={openNodes.includes(nodeId)} timeout="auto" unmountOnExit>
            <List disablePadding dense component="div">
                {sorter(children, nodes).map(id => (
                    <Component key={id} depth={depth + 1} nodeId={id} {...{ activeDeviceId, getPrimaryText, getSecondaryText, indexedNodes, nodes, openNodes, selectNode, sorter, toggleNode }} />
                ))}
            </List>
        </Collapse>
    ) : null;

    const style = {
        backgroundColor: nodeId === activeDeviceId ? '#DDDDDD' : null,
        paddingLeft: depth * 12 + (children ? 0 : 43)
    };

    return (
        <>
            <StyledListItem button disableGutters selected={nodeId === activeDeviceId} style={style}>
                {Icon}
                <StyledListItemText
                    data-id={nodeId}
                    onClick={handleSelect}
                    primary={getPrimaryText(node)}
                    secondary={getSecondaryText(node)}
                />
            </StyledListItem>
            {Children}
        </>
    );
};

Component.propTypes = {
    activeDeviceId: PropTypes.string,
    indexedNodes: PropTypes.object.isRequired,
    nodes: PropTypes.object.isRequired,
    openNodes: PropTypes.array.isRequired,
    getPrimaryText: PropTypes.func.isRequired,
    getSecondaryText: PropTypes.func.isRequired,
    selectNode: PropTypes.func.isRequired,
    sorter: PropTypes.func.isRequired,
    toggleNode: PropTypes.func.isRequired,
    nodeId: PropTypes.string.isRequired,
    depth: PropTypes.number
};

export default Component;
