import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {

    const currentUser = useSelector( state => state.auth.currentUserId);

    const render = props => {
        if (currentUser) {
            return <Component {...props} />;
        }

        return (
            <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
            }}/>
        );
    };

    return (
        <Route {...rest} render={render}/>
    );
};

export default withRouter(PrivateRoute);
