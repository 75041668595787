import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

// Styled components
const Root = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const Wrapper = styled('div')(({ theme }) => ({
    margin: theme.spacing(1),
    position: 'relative',
}));

const ButtonProgress = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.primary.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
}));

const Component = ({ disabled, label, loading, submitting, size, type, onClick }) => {
    return (
        <Root>
            <Wrapper>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={disabled || loading || submitting}
                    onClick={!submitting ? onClick : undefined}
                    size={size}
                    type={type}
                >
                    {label}
                </Button>
                {submitting && <ButtonProgress size={24} />}
            </Wrapper>
        </Root>
    );
};

Component.defaultProps = {
    disabled: false,
};

Component.propTypes = {
    disabled: PropTypes.bool.isRequired,
    label: PropTypes.string,
    loading: PropTypes.bool,
    submitting: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.string,
    type: PropTypes.string,
};

export default Component;
