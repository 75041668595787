import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';

import ExpandedPanel from 'Components/ExpandedPanel';
import Loader from 'Components/ScopedLoader';
import SimpleList from 'Components/SimpleList';
import TabsToolbar from 'Components/TabsToolbar';
import icons from 'utils/ui/icons';

import { HEADER_HEIGHT, SPACER_MINI, TABS_HEIGHT } from 'utils/ui/constants';

// Styled components
const MainContainer = styled(Grid)(({ theme }) => ({
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    backgroundColor: theme.palette.backgrounds.color.main,
    padding: '0px 10px 0px',
}));

const HeaderContainer = styled(Grid)(() => ({
    height: `${TABS_HEIGHT + SPACER_MINI}px`,
}));

const BottomContainer = styled(Grid)(() => ({
    height: `calc(100% - ${TABS_HEIGHT + SPACER_MINI + SPACER_MINI}px)`,
    paddingBottom: 5,
    overflowY: 'auto',
}));

const Component = ({ loading, panelHeader, properties, tabsHeader, onBack, onDelete, onEdit }) => {
    const buttons = [];

    if (onBack) {
        buttons.push({ icon: icons.back, onClick: onBack, tooltip: 'Back' });
    }

    if (onEdit) {
        buttons.push({ icon: icons.editConfig, onClick: onEdit, tooltip: 'Edit' });
    }

    if (onDelete) {
        buttons.push({
            icon: icons.trash,
            confirmationHeader: 'Delete Confirmation',
            confirmationText: 'Are you sure you want to delete this entity?',
            submitting: loading,
            onClick: onDelete,
            tooltip: 'Delete',
        });
    }

    return (
        <MainContainer container size={12}>
            <HeaderContainer size={12}>
                <TabsToolbar buttons={buttons} tabs={[{ text: tabsHeader, id: 'none' }]} />
            </HeaderContainer>

            {loading ? (
                <Loader />
            ) : (
                <BottomContainer size={12}>
                    <ExpandedPanel header={panelHeader} loading={loading}>
                        <SimpleList content={properties} />
                    </ExpandedPanel>
                </BottomContainer>
            )}
        </MainContainer>
    );
};

Component.propTypes = {
    loading: PropTypes.bool,
    onBack: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    panelHeader: PropTypes.string,
    properties: PropTypes.array,
    tabsHeader: PropTypes.string,
};

export default Component;
