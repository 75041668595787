import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, FilledInput, styled } from '@mui/material';

const StyledFormControl = styled(FormControl)({
    minWidth: '100%',
    marginTop: 16,
    marginBottom: 8,
});

const StyledFilledInput = styled(FilledInput)({
    height: 30,
    fontSize: '0.65rem',
    paddingBottom: 16,
});

const StyledInputLabel = styled(InputLabel)({
    transform: 'translate(12px, -15px) scale(0.75)',
});

const StyledMenuItem = styled(MenuItem)({
    fontSize: '0.65rem',
    paddingTop: 2,
    paddingBottom: 2,
});

const SelectComponent = ({ disabled, schema, input, label, meta }) => {
    const { allowBlank, formData } = schema;

    return (
        <StyledFormControl disabled={disabled} error={meta.touched && !!meta.error} variant="filled">
            <StyledInputLabel htmlFor="filled-select" shrink>
                {label}
            </StyledInputLabel>
            <Select
                inputProps={input}
                input={<StyledFilledInput id="filled-select" />}
            >
                {allowBlank && <StyledMenuItem key="" value="" />}
                {formData.map((item) => (
                    <StyledMenuItem key={item.id} value={item.id}>
                        {item.name}
                    </StyledMenuItem>
                ))}
            </Select>
            {meta.touched && !!meta.error && (
                <FormHelperText error>{meta.error}</FormHelperText>
            )}
        </StyledFormControl>
    );
};

SelectComponent.propTypes = {
    disabled: PropTypes.bool,
    schema: PropTypes.object.isRequired,
    input: PropTypes.object.isRequired, // Injected by Redux Forms
    label: PropTypes.string,
    meta: PropTypes.object, // Injected by Redux Forms
};

export default SelectComponent;
