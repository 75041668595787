import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Drawer from 'Components/Drawer';
import TabsToolbar from 'Components/TabsToolbar';
import { actions as indicatorsActions } from 'ducks/indicators';
import { loadBuilding } from 'ducks/loadBuilding';
import EditIndicator from 'Pages/Indicators/Edit';
import icons from 'utils/ui/icons';
import Info from './Info';
import { HEADER_HEIGHT, SPACER_MINI, TABS_HEIGHT } from 'utils/ui/constants';
import theme from '~/theme.js';

const MainContainer = styled(Grid)(({theme}) => ({
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    backgroundColor: theme.palette.backgrounds.color.main,
    padding: '0px 10px 0px',
    width: '100%',
}));

const HeaderContainer = styled(Grid)({
    height: `${TABS_HEIGHT + SPACER_MINI}px`,
    width: '100%',
});

const BottomContainer = styled(Grid)({
    height: `calc(100% - ${TABS_HEIGHT + SPACER_MINI + SPACER_MINI}px)`,
    paddingBottom: 5,
    overflowY: 'auto',
    width: '100%',
});

const IndicatorPage = ({ actions = true, id, match }) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const indicatorId = id || match?.params?.indicatorId;

    const loading = useSelector((state) => state.indicators.loading);
    const model = useSelector((state) => state.indicators.models[indicatorId]);

    const remove = indicatorsActions.useRemove();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(indicatorsActions.fetchOne(indicatorId));
    }, [dispatch, indicatorId]);

    const onDelete = () => remove(indicatorId, () => dispatch(loadBuilding(model?.building_id)));
    const onEdit = () => setOpenDrawer(true);
    const onClose = () => setOpenDrawer(false);

    const buttons = actions
        ? [
            { icon: icons.editConfig, onClick: onEdit, tooltip: 'Edit' },
            {
                icon: icons.trash,
                confirmationHeader: 'Delete Confirmation',
                confirmationText: 'Are you sure you want to delete this entity?',
                submitting: loading,
                onClick: onDelete,
                tooltip: 'Delete',
            },
        ]
        : [];

    return (
        <MainContainer container>
            <HeaderContainer>
                <TabsToolbar
                    buttons={buttons}
                    tabs={[
                        { text: 'Indicator details', id: 'properties' },
                        { text: 'Measurements', id: 'measurements' },
                    ]}
                />
            </HeaderContainer>
            <BottomContainer>
                <Info model={model} />
            </BottomContainer>
            <Drawer onClose={onClose} open={openDrawer}>
                <EditIndicator indicator={model} closeDrawer={onClose} />
            </Drawer>
        </MainContainer>
    );
};

export default IndicatorPage;
