import Alert from 'Components/Alert';
import { cleanUserFeedback } from 'ducks/userFeedback';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const defaultPlacement = { vertical: 'bottom', horizontal: 'left' };
const defaultTimeout = 5000;

const Message = props => {
    const userFeedback = useSelector(state => state.userFeedback);

    const dispatch = useDispatch();
    const cleanUserFb = () => dispatch(cleanUserFeedback())

    return (
        <Alert
            open={userFeedback.message.length !== 0}
            message={userFeedback.message}
            handleClose={cleanUserFb}
            placement={userFeedback.placement || defaultPlacement}
            timeout={userFeedback.timeout || defaultTimeout}
            variant={userFeedback.type}
        />
    );
};

export default Message;
