import EntityView from 'Components/EntityView';
import { actions } from 'ducks/equipmentCategories';
import React from 'react';
import { useSelector } from 'react-redux';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';

const properties = model => [
    { label: 'ID', content: model.id },
    { label: 'Name', content: model.name },
    { label: 'In protocol', content: model.in_protocol_name },
    { label: 'Output protocol', content: model.out_protocol_name },
    { label: 'Creation', content: `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
    { label: 'Update', content: `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const Component = props => {

    const loading = useSelector(state => state.equipmentCategories.loading);
    const model = useSelector(state =>  state.equipmentCategories.models[state.drawerView.entityId]);
    const root = useSelector(state => state.users.models[state.auth.currentUserId].root);
    const stateProps = {loading, model, root};

    const actionProps = ({
        remove: actions.useRemove()
    });

    return <EntityView
        disableDelete
        disableEdit
        tabsHeader={'Equipment Category details'}
        properties={properties}
        {...stateProps}
        {...actionProps}
        {...props}
    />;
};

export default Component;
