import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Marker from 'Components/Maps/Marker';
import formatTimestamp from 'utils/formatTimestamp';
import history from '~/history';

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(1),
    overflowX: 'auto'
}));

const StyledTable = styled(Table)({
    minWidth: 150
});

const StyledTableRow = styled(TableRow)({
    height: 38
});

const StyledTableCell = styled(TableCell)({
    fontWeight: 'bold'
});

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.default,
    fontWeight: 'bold'
}));

const StyledTooltipContainer = styled('div')({
    margin: 2,
    width: '100%'
});

const StyledHeader = styled('div')({
    marginBottom: 5
});

// DevicesTable Component
const DevicesTable = ({ building }) => {
    if (building.devices.length === 0) {
        return <div>No devices available</div>;
    }

    return (
        <StyledPaper>
            <StyledTable padding="dense">
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell>Device Name</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Last Status Change</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {building.devices.map(device => (
                        <StyledTableRow key={device.id}>
                            <StyledTableCell>{device.name}</StyledTableCell>
                            <StyledTableCell>{device.status}</StyledTableCell>
                            <StyledTableCell>
                                {formatTimestamp(device.status_updated_at, building.timezone)}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </StyledPaper>
    );
};

DevicesTable.propTypes = {
    building: PropTypes.object.isRequired
};

const getTooltip = (building) => (
    <StyledTooltipContainer>
        <StyledHeader>
            <StyledTypography>{building.name}</StyledTypography>
        </StyledHeader>
        <DevicesTable building={building} />
    </StyledTooltipContainer>
);

const onClick = buildingId => () => history.push(`/buildings/${buildingId}`);

const Component = ({ position, building, tooltipID, tooltipMutex, handleSetTooltipMutex }) => {

    return <Marker
        tooltipID={tooltipID}
        tooltipMutex={tooltipMutex}
        handleSetTooltipMutex={handleSetTooltipMutex}
        onClick={onClick(building.id)}
        position={position}
        status={building.status}
        tooltip={getTooltip(building)}
    />
};

Component.propTypes = {
    building: PropTypes.object.isRequired,
    position: PropTypes.object.isRequired,
    tooltipID: PropTypes.number,
    tooltipMutex: PropTypes.number,
    handleSetTooltipMutex: PropTypes.func,
};

export default Component;
