import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/equipment';
import React from 'react';
import { useSelector } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {

    const currentUser = useSelector(state => state.users.models[state.auth.currentUserId]);
    const metadatas = useSelector(state => selectorMetadatas.metadataFromEquipment(state));
    const model = useSelector(state => state.equipments.models[state.drawerView.entityId]);
    const stateProps = { currentUser, metadatas, model };

    const stateActions = ({
        create: actions.useCreate(),
        update: actions.useUpdate()
    });

    const formSchema = [
        {
            type: 'text',
            label: 'Name\x2a',
            name: 'name',
            validate: [validators.required]
        },
        {
            type: 'textArea',
            label: 'Description',
            name: 'description'
        },
        ...metadataFields(metadatas, model)
    ];

    if (model.usable && currentUser.superuser) {
        formSchema.push(
            {
                type: 'checkBox',
                label: 'Usable Equipment?',
                name: 'usable'
            });
    }

    return (
        <EntityManageView
            formSchema={formSchema}
            formInitialValues={model}
            label="Edit Equipment"
            {...stateProps}
            {...stateActions}
            {...props}
        />
    );
};

export default Component;
