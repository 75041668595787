import { useEffect, useState } from 'react';
import { LoginCard } from '~/ComponentsTW/layout/login-card';
import { AlertBox, Form, FormLabel, Button, PasswordInput, StandaloneLink, Icon } from '~/ComponentsTW/wm';
import { apiRequest } from 'utils/request';
import * as React from 'react';

export function ResetPassword({ onAlert, code, backToLogin }) {
    const
            [loading, setLoading] = useState(false),
            [password, setPassword] = useState(''),
            [passwordConfirmation, setPasswordConfirmation] = useState(''),
            [passwordRequirements, setPasswordRequirements] = useState(WiseMetering.utils.validatePassword(password, passwordConfirmation));

    useEffect(() => {
        setPasswordRequirements(WiseMetering.utils.validatePassword(password, passwordConfirmation));
    }, [password, passwordConfirmation]);

    const canSubmit = Object.values(passwordRequirements).every(req => req.met);

    const handleGeneratePassword = () => {
        const generatedPassword = WiseMetering.utils.generatePassword();
        setPassword(generatedPassword);
    };

    const onHandleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const data = {
            password,
            password_confirmation: passwordConfirmation
        };

        try {
            const response = await apiRequest(`/reset_password/${code}`, 'PUT', data);

            if (response.status === 200) {
                onAlert('Successfully changed password', 'success', 'Success');
                backToLogin();
            }
        } catch(err) {
            const errorMessage = err.response?.data?.error || 'An error occurred';

            onAlert(errorMessage, 'destructive', 'Failed')
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    return (
            <div className="tw-grid tw-grid-cols-1">
                <LoginCard>
                    <Form>
                        <FormLabel htmlFor="email">insert a new password</FormLabel>
                        <PasswordInput
                                id="password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                        />
                        <FormLabel htmlFor="email">confirm the new password</FormLabel>
                        <PasswordInput
                                id="password_confirmation"
                                required
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                        />
                        <Button type="submit" loading={loading} className="tw-w-full" variant="secondary" onClick={onHandleSubmit} disabled={!canSubmit}>
                            Submit
                        </Button>
                    </Form>
                </LoginCard>
                <AlertBox active={true} variant="info" title="Password requirements">
                    {
                        <div>
                            <ul className="tw-space-y-2 tw-list-none tw-p-0 tw-m-0 tw-mt-2">
                                { Object.entries(passwordRequirements || {}).map(([key, { met, message }]) => (
                                        <li key={key} className="tw-flex tw-items-center tw-w-full tw-text-xs">
                                            { met ? (
                                                    <Icon icon="circleCheck" size="small" color="success"/>
                                            ) : (
                                                    <Icon icon="exclamationCircle" size="small" color="destructive"/>
                                            )}
                                            { message }
                                        </li>
                                ))}
                            </ul>
                            <Button className="tw-w-full tw-mt-3" variant="info" onClick={ handleGeneratePassword }>
                                Generate a Password
                            </Button>
                        </div>
                    }
                </AlertBox>
                <StandaloneLink onAction={backToLogin} icon="arrowLeftCircle" text="Back to Login" />
            </div>
    );
}
