import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SpinButton from 'Components/Buttons/Button';
import PropTypes from 'prop-types';
import React from 'react';

const Component = props => {
    const { alert, alertHeader = '', open, submitting } = props;
    const { onConfirm, onClose, onNo } = props;

    const handleAction = () => onConfirm();

    return (
        <Dialog
            open={open}
            onClose={onClose ? onClose : () => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {alertHeader}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {alert}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onNo} color="primary" variant="contained" disabled={submitting}>
                    No
                </Button>
                <SpinButton onClick={handleAction} label="Yes" submitting={submitting}/>

            </DialogActions>
        </Dialog>
    );
};

export default Component;

Component.propTypes = {
    alert: PropTypes.string,
    alertHeader: PropTypes.string,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func
};
