import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as deviceActions } from 'ducks/devices';
import { actions as measurementActions } from 'ducks/measurements';
import { sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields, serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const maxDelayOptions = [
    { id: 14400, name: '4h' },
    { id: 28800, name: '8h' },
    { id: 86400, name: '24h' },
    { id: 172800, name: '48h' }
];

const Component = props => {
    const { installationZones } = props;
    const { closeDrawer } = props;

    const device = useSelector(state =>  state.devices.models[props.id]);
    const equipments = useSelector(state =>  state.equipments.models);
    const electricityMeterType = useSelector(state =>  state.bootstrap.equipment_types.find(equipmentType => equipmentType.name === 'Electricity Meter'));
    const metadatas = useSelector(state =>  selectorMetadatas.metadataFromDevice(state, props));
    const submitting = useSelector(state =>  state.devices.submitting);

    let formSchema = [
        {
            type: 'text',
            label: 'Name\x2a',
            name: 'name',
            validate: [validators.required]
        },
        {
            formData: sortBy(installationZones, 'full_path').map(({ id, full_path }) => ({ id, name: full_path })),
            label: 'Installation Zone\x2a',
            name: 'installation_zone_id',
            type: 'select',
            validate: [validators.required]
        },
        {
            type: 'textArea',
            label: 'Description',
            name: 'description',
            rows: 8
        },
        {
            type: 'select',
            label: 'Maximum Allowed Delay',
            name: 'max_delay',
            formData: maxDelayOptions
        },
        {
            label: 'Integration?',
            name: 'integration',
            type: 'checkBox'
        }
    ];

    const equipment = Object.values(equipments).find(equipment => equipment.id === device.equipment_id);
    if (equipment.equipment_type_id === electricityMeterType.id) {
        formSchema.push(
            {
                formData: [{ id: '1-phase', name: '1-phase' }, { id: '3-phase', name: '3-phase' }],
                label: 'TI Type\x2a',
                name: 'ti_type',
                type: 'select',
                validate: [validators.required]
            },
            {
                label: 'TI Current\x2a',
                name: 'ti_current',
                type: 'number',
                validate: [validators.required]
            }
        );
    }

    formSchema.push(...metadataFields(metadatas, device));

    const dispatch = useDispatch();
    const { fetchDevice, fetchMeasurements, update } = ({
        fetchDevice: id => dispatch(deviceActions.fetchOne(id)),
        fetchMeasurements: measurementActions.useFetchAll(),
        update: deviceActions.useUpdate()
    });

    useEffect(() => {
        fetchDevice(device.id);
    }, []);

    const onSubmit = values => update(
        device.id,
        serializeForm(values, formSchema, device),
        () => {
            fetchMeasurements({ device_id: device.id }, null);
            closeDrawer();
        }
    );

    return (
        <FormViewer label="Edit Device">
            <Form
                formSchema={formSchema}
                formInitialValues={device}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;
