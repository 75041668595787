import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import IconButton from 'Components/Buttons/IconButton';
import Tabs from 'Components/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '~/theme.js';

const MainGrid = styled(Grid)({
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    width: '100%',
    backgroundColor: theme.palette.background.paper
});

const StyledTabs = styled(Tabs)({
    width: '100%',
    minHeight: '100px', // Set height of the tabs
    '& .MuiTabs-flexContainer': {
        minHeight: '100px'
    },
    '& .MuiTab-root': {
        minHeight: '100px'
    }
});

const PaperButtons = styled(Paper)({
    paddingLeft: 20,
    display: 'flex',
    height: '100%',
    width: '100%'
});

const Component = ({ currentTab, buttons = [], tabs }) => {
    const Buttons = buttons.map(button => <IconButton key={button.icon} {...button} />);

    return (
        <MainGrid container>
            <Grid elevation={0}>
                <StyledTabs currentTab={currentTab} elevation={0} tabs={tabs}/>
            </Grid>
            <Grid>
                <PaperButtons square elevation={0}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        {Buttons}
                    </Grid>
                </PaperButtons>
            </Grid>
        </MainGrid>
    );
};

export default Component;

Component.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            id: PropTypes.string
        })
    ).isRequired,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string,
            icon: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.arrayOf(PropTypes.string)
            ]),
            href: PropTypes.string,
            onClick: PropTypes.func,
            tooltip: PropTypes.string,
            spin: PropTypes.bool,
            requireConfirmation: PropTypes.bool
        })
    )
};
