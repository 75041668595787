import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import Icon from 'Components/Icon';
import icons from 'utils/ui/icons';
import amber from '@mui/material/colors/amber';
import green from '@mui/material/colors/green';

// Variant to icon mapping
const variantIcon = {
    success: icons.check,
    warning: icons.warning,
    error: icons.error,
    info: icons.info,
};

// Styled Components
const StyledSnackbarContent = styled(SnackbarContent, {
    shouldForwardProp: (prop) => prop !== 'variant',
})(({ theme, variant }) => {
    const backgroundColors = {
        success: green[600],
        error: theme.palette.error.dark,
        info: theme.palette.primary.dark,
        warning: amber[700],
    };

    return {
        backgroundColor: backgroundColors[variant] || theme.palette.grey[700],
        '& .icon': {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(1),
        },
        '& .message': {
            display: 'flex',
            alignItems: 'center',
            textAlign: 'justify',
            fontSize: 12,
        },
    };
});

const Alert = ({ message, open, placement, timeout, variant = 'error', handleClose }) => {
    return (
        <Snackbar
            anchorOrigin={placement || { vertical: 'top', horizontal: 'right' }}
            open={open}
            autoHideDuration={timeout}
            onClick={handleClose}
            onClose={handleClose}
        >
            <StyledSnackbarContent
                variant={variant}
                aria-describedby="client-snackbar"
                message={
                    <div id="client-snackbar" className="message">
                        <Icon icon={variantIcon[variant]} className="icon" />
                        {message}
                    </div>
                }
            />
        </Snackbar>
    );
};

Alert.propTypes = {
    handleClose: PropTypes.func,
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    placement: PropTypes.shape({
        vertical: PropTypes.oneOf(['top', 'bottom']),
        horizontal: PropTypes.oneOf(['left', 'center', 'right']),
    }),
    timeout: PropTypes.number,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
};

export default Alert;
