import { ListItemIcon, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { styled } from '@mui/system';
import Icon from 'Components/Icon';
import { logout } from 'ducks/authentication';
import { closeMenu as uiCloseMenu } from 'ducks/ui';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icons from 'utils/ui/icons';
import globals from '~/globals';
import history from '~/history';


const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.primary.white,
    '&:hover': {
        background: lighten(theme.palette.primary.main, 0.2)
    },
}))

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.primary.white
}))

const StyledMenuList = styled(MenuList)(({ theme }) => ({
    background: theme.palette.primary.main
}))

const StyledMenu = styled(Menu)(({ theme }) => ({
    transform: 'translate(8px, 8px)',
    '& .MuiMenu-paper': {
        background: theme.palette.primary.main,
        width: 250,
        outline: 'none',
    },
    '& .MuiList-root:focus': {
        outline: 'none', // This takes out the blue lines of focus
    },
}))

const generateMenu = (classes, list) => list.map(item => (
        <StyledMenuItem key={item.icon} onClick={item.onClick}>
            <StyledListItemIcon>
                <Icon icon={icons[item.icon]}/>
            </StyledListItemIcon>
            <Typography variant="inherit">{item.text}</Typography>
        </StyledMenuItem>
    )
);

const Component = props => {
    const { anchorEl, classes } = props;

    const showMenu = useSelector(state => state.ui.showMenu);
    const currentUser = {} || useSelector(state => state.users.models[state.auth.currentUserId]);

    const dispatch = useDispatch();
    const closeMenu = () => dispatch(uiCloseMenu());

    const manageProfile = () => {
        history.push('/profile');
        dispatch(uiCloseMenu());
    };

    const list = [];

    if (currentUser.superuser) {
        list.push({ icon: 'delays', onClick: () => window.open(`${globals.apiUrl}/static/report.html`, '_blank'), text: 'Delays Report' });
    }

    list.push(
        { icon: 'profile', onClick: manageProfile, text: 'Profile' },
        { icon: 'logout', onClick: () => dispatch(logout()), text: 'Logout' }
    );

    return (
        <StyledMenu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={closeMenu}
            open={showMenu}
        >
            <StyledMenuList onMouseLeave={closeMenu}>
                {generateMenu(classes, list)}
            </StyledMenuList>
        </StyledMenu>
    );
};

export default Component;
