import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as indicatorsActions } from 'ducks/indicators';
import React from 'react';
import { useSelector } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const formSchema = [
    {
        type: 'text',
        label: 'Name\x2a',
        name: 'name',
        validate: [validators.required]
    },
    {
        type: 'checkBox',
        label: 'Ignore status',
        name: 'ignore_status'
    },
    {
        type: 'textArea',
        label: 'Description',
        name: 'description'
    },
    {
        type: 'text',
        label: 'Max value (if omitted will be inherited)',
        name: 'max_value',
        validate: [validators.number]
    },
    {
        type: 'text',
        label: 'Min value (if omitted will be inherited)',
        name: 'min_value',
        validate: [validators.number]
    }
];

const Component = props => {
    const { closeDrawer, indicator } = props;

    const submitting = useSelector(state => state.indicators.submitting);

    const update = indicatorsActions.useUpdate()

    const onSubmit = values => {
        update(
            indicator.id,
            serializeForm(values, formSchema, indicator),
            closeDrawer
        );
    };

    return (
        <FormViewer label={'Indicator Form'}>
            <Form
                formSchema={formSchema}
                formInitialValues={indicator}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;
