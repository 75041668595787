import PanelDivider from 'Components/PanelDivider';
import { actions as deviceActions } from 'ducks/devices';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeviceDetails from './DeviceDetails';
import WiseBoxDetails from './WiseBoxDetails';

const Component = props => {
    const { childDevices, device, installationZones } = props;
    const remove = deviceActions.useRemove();

    const submitting = useSelector(state => state.devices.submitting);

    const dispatch = useDispatch();
    useEffect(() => {
        if (device.new_wise_box) {
            dispatch(deviceActions.fetchOne(device.id));
        }
    }, [device.id]);

    return (
        <>
            <DeviceDetails childDevices={childDevices} installationZones={installationZones} remove={remove} submitting={submitting} device={device}/>
            <PanelDivider/>
            <WiseBoxDetails device={device} submitting={submitting}/>
            <PanelDivider/>
        </>
    );
};

export default Component;
