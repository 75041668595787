import React from 'react';
import { useSelector } from 'react-redux';
import Default from './Default';
import WiseBox from './WiseBox';

const Component = props => {

    const device = useSelector(state => state.devices.models[props.deviceId]);

    if (device.new_wise_box) {
        return (
            <WiseBox {...props}/>
        );
    } else {
        return (
            <Default {...props}/>
        );
    }
};

export default Component;
