import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import SimpleList from 'Components/SimpleList';
import UptimeStatus from 'Components/UptimeStatus';
import ShowEquipment from 'Pages/Equipment/Show';
import EditDevice from 'Pages/Tree/Device/Edit';
import KnownProblem from 'Pages/Tree/Device/KnownProblem';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { unixToDate } from 'utils/date';
import formatDescription from 'utils/formatDescription';
import getUserName from 'utils/getUserName';
import statusToComponent from 'utils/statusToComponent';
import icons from 'utils/ui/icons';

const Component = props => {
    const { childDevices, device, installationZones, submitting } = props;
    const { remove } = props;

    const [drawer, setOpenDrawer] = useState(null);
    const closeDrawer = () => setOpenDrawer(null);

    const deviceInstallationZone = installationZones.find((installationZone) => installationZone.id === device.installation_zone_id);

    const deviceAttributes = [
        { label: 'ID', content: device.id },
        { label: 'Name', content: device.name },
        { label: 'Description', content: formatDescription(device.description) },
        { label: 'Installation Zone', content: deviceInstallationZone.full_path },
        { label: 'Creation', content: `${unixToDate(device.created_at)} by ${getUserName(device.created_by)}` },
        { label: 'Last Modification', content: unixToDate(device.updated_at) },
        { label: 'Maximum Allowed Delay', content: `${device.max_delay / 3600}h` },
        { label: 'Status', content: statusToComponent(device.status) },
        {
            label: 'Equipment',
            content: (
                <a href="#" onClick={event => {
                    event.preventDefault();
                    setOpenDrawer('showEquipment');
                }}>
                    {device.equipment_name}
                </a>
            )
        },
        { label: 'Uptime', content: <UptimeStatus uptime={device.uptime}/> }
    ];

    const deviceButtons = [];

    deviceButtons.push(
        {
            icon: icons.edit,
            onClick: () => setOpenDrawer('editDevice'),
            tooltip: 'Edit'
        },
        {
            disabled: !device.active,
            icon: icons.check_problem,
            onClick: () => setOpenDrawer('knownProblem'),
            tooltip: 'Known Problem'
        }
    );

    if (childDevices.length === 0) {
        deviceButtons.push(
            {
                confirmationHeader: 'Delete Confirmation',
                confirmationText: 'Are you sure you want to delete?',
                submitting: submitting,
                icon: icons.trash,
                onClick: () => remove(device.id, `/buildings/${device.building_id}`),
                tooltip: 'Delete'
            }
        );
    }

    let DrawerContent;
    if (drawer === 'editDevice') {
        DrawerContent = (
            <EditDevice id={device.id} installationZones={installationZones} closeDrawer={closeDrawer}/>
        );
    } else if (drawer === 'knownProblem') {
        DrawerContent = (
            <KnownProblem id={device.id} closeDrawer={closeDrawer}/>
        );
    } else if (drawer === 'showEquipment') {
        DrawerContent = (
            <ShowEquipment disableButtons id={device.equipment_id}/>
        );
    }

    return (
        <>
            <Panel buttons={deviceButtons}>
                <SimpleList content={deviceAttributes}/>
            </Panel>
            <Drawer onClose={closeDrawer} open={!!drawer}>
                {DrawerContent}
            </Drawer>
        </>
    );
};

export default Component;

Component.propTypes = {
    device: PropTypes.object,
    installationZones: PropTypes.array,
    childDevices: PropTypes.array,
    submitting: PropTypes.bool,
    remove: PropTypes.func
};

