import { Button as ShadcnButton } from '~/ComponentsTW/ui/button'; // Import the base Button component
import * as React from 'react';;

const Button = React.forwardRef(
  ({ className, loading, children, disabled, ...props }, ref) => {
    return (
      <ShadcnButton
        className={`tw-relative ${className}`}
        disabled={loading || disabled} // Disable the button when loading
        ref={ref}
        {...props}
      >
        {loading ? (
            <svg
                className="tw-w-4 tw-h-4 tw-border-4 tw-border-t-transparent tw-border-primary tw-rounded-full tw-animate-spin"
                viewBox="0 0 24 24"
            />
        ) : (
            children
        )}
      </ShadcnButton>
    );
  }
);

Button.displayName = 'Button';

export { Button };
