import Grid from '@mui/material/Grid2';
import Input from '@mui/material/Input';
import Icon from 'Components/Icon';
import React from 'react';
import icons from 'utils/ui/icons';
import { styled } from '@mui/material/styles';

// Styled Grid item for the search icon
const GridIconWrapper = styled(Grid)(({ theme }) => ({
    display: 'grid',
    placeItems: 'center',
    padding: theme.spacing(0, 1) // Add some padding around the icon
}));

// Styled Grid container to center the entire search bar
const CenteringGridContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',         // Use flexbox for centering
    justifyContent: 'center', // Center horizontally
    alignItems: 'center',     // Align items vertically (optional, but good practice)
    width: '100%',            // Take up the full width of its parent
}));

const SearchInput = ({ loading, onSearchChange, searching, searchValue }) => {
    return (
        <CenteringGridContainer container>
            <GridIconWrapper>
                <Icon icon={(loading || searching) ? icons.spinner : icons.search} spin={loading || searching}/>
            </GridIconWrapper>
            <Grid xs>
                <Input
                    variant="outlined" // Or "filled", "standard"
                    size="small"
                    disabled={loading}
                    //disableUnderline
                    placeholder={loading ? 'Loading data…' : 'Search…'}
                    onChange={event => {
                        onSearchChange(event.target.value);
                    }}
                    value={searchValue}// Adjust size as needed
                />
            </Grid>
        </CenteringGridContainer>
    );
};

export default SearchInput;
