import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import SimpleList from 'Components/SimpleList';
import UploadConfFile from 'Components/UploadConfFile';
import UptimeStatus from 'Components/UptimeStatus';
import { actions as deviceActions } from 'ducks/devices';
import ShowEquipment from 'Pages/Equipment/Show';
import EditDevice from 'Pages/Tree/Device/Edit';
import KnownProblem from 'Pages/Tree/Device/KnownProblem';
import ReplaceDevice from 'Pages/Tree/Device/ReplaceDevice';
import CreateTemplate from 'Pages/Tree/Device/WiseBox/CreateTemplate';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unixToDate } from 'utils/date';
import displayMetadata from 'utils/displayMetadata';
import formatDescription from 'utils/formatDescription';
import getUserName from 'utils/getUserName';
import statusToComponent from 'utils/statusToComponent';
import icons from 'utils/ui/icons';
import globals from '~/globals';

const generateProperties = (device, setOpenDrawer) => {
    let properties = [
        { label: 'ID', content: device.id },
        { label: 'Name', content: device.name },
        { label: 'Description', content: formatDescription(device.description) },
        { label: 'Installation Zone', content: device.installation_zone_full_path },
        { label: 'Creation', content: `${getUserName(device.created_by)} @ ${unixToDate(device.created_at)}` },
        { label: 'Last Modification', content: `${getUserName(device.updated_by)} @ ${unixToDate(device.updated_at)}` },
        { label: 'Maximum Allowed Delay', content: `${device.max_delay / 3600}h` },
        { label: 'Status', content: statusToComponent(device.status) },
        {
            label: 'Equipment',
            content: (
                <a href="#" onClick={event => {
                    event.preventDefault();
                    setOpenDrawer('showEquipment');
                }}>
                    {device.equipment_name}
                </a>
            )
        }
    ];

    if (!device.parent_id) {
        properties.push({ label: 'Uptime', content: <UptimeStatus uptime={device.uptime}/> });
    }

    if (device.wise_box) {
        properties.push({ label: 'MAC Address', content: device.mac_address });
    }

    if (!device.equipment_name.startsWith('Wise')) {
        properties.push({ label: 'Integration?', content: device.integration ? 'Yes' : 'No' });
    }

    if (device.ti_current) {
        properties.push({ label: 'TI Current', content: device.ti_current });
    }

    if (device.ti_type) {
        properties.push({ label: 'TI Type', content: device.ti_type });
    }

    return [...properties, ...displayMetadata(device.metadata_values)];
};

const Component = props => {
    const { childDevices, device, installationZones, measurements } = props;

    const [drawer, setOpenDrawer] = useState(null);
    const closeDrawer = () => setOpenDrawer(null);

    const submitting = useSelector(state => state.devices.submitting);

    const properties = generateProperties(device, setOpenDrawer);

    const
        hasChildDevices = !!childDevices.length,
        hasActiveIndicators = !!measurements.find(m => m.indicator_id && !m.indicator_stop);

    const dispatch = useDispatch();
    const { replace, regenerateSeedFiles, remove } = {
        replace: (id, equipmentId) => dispatch(deviceActions.replace(id, equipmentId)),
        regenerateSeedFiles: id => dispatch(deviceActions.regenerateSeedFiles(id)),
        remove: (id, redirect) => dispatch(deviceActions.remove(id, redirect))
    };

    const buttons = [
        {
            icon: icons.edit,
            onClick: () => setOpenDrawer('editDevice'),
            tooltip: 'Edit'
        },
        {
            disabled: !device.active,
            icon: icons.check_problem,
            onClick: () => setOpenDrawer('knownProblem'),
            tooltip: 'Known Problem'
        }
    ];

    if (!hasActiveIndicators && !hasChildDevices) {
        buttons.push(
            {
                confirmationHeader: 'Delete Confirmation',
                confirmationText: 'Are you sure you want to delete?',
                submitting: submitting,
                icon: icons.trash,
                onClick: () => remove(device.id, `/buildings/${device.building_id}`),
                tooltip: 'Delete'
            }
        );
    }

    if (device.out_protocol_name === 'Reading Metal') {
        if (hasActiveIndicators) {
            buttons.push({
                icon: icons.replace,
                onClick: () => setOpenDrawer('replaceDevice'),
                tooltip: 'Replace device'
            });
        } else {
            buttons.push({
                confirmationHeader: 'Replace Confirmation',
                confirmationText: 'Are you sure you want to mark device as replaced?',
                submitting: submitting,
                icon: icons.replace,
                onClick: () => replace(device.id),
                tooltip: 'Mark device as replaced'
            });
        }
    }

    if (device.wise_box) {
        buttons.push(
            {
                confirmationHeader: 'Replace Confirmation',
                confirmationText: 'Are you sure you want change this to NewWiseBox? This process is irreversible.',
                submitting: submitting,
                icon: icons.unclaimed,
                onClick: () => replace(device.id),
                tooltip: 'Change to NewWiseBox'
            },
            {
                icon: icons.readConf,
                onClick: () => window.open(`${globals.seedUrl}/${device.id}/read_conf`),
                tooltip: 'Read configuration'
            },
            {
                icon: icons.actuators,
                onClick: () => window.open(`${globals.seedUrl}/${device.id}/act_conf`),
                tooltip: 'Actuation configuration'
            },
            {
                icon: icons.rulesConf,
                onClick: () => window.open(`${globals.seedUrl}/${device.id}/rules`),
                tooltip: 'Schedules configuration'
            },
            {
                confirmationHeader: 'Operation Confirmation',
                confirmationText: 'Are you sure you want to regenerate?',
                submitting: submitting,
                icon: icons.refresh,
                onClick: () => regenerateSeedFiles(device.id),
                tooltip: 'Regenerate configuration files'
            },
            {
                icon: icons.fileDownload,
                onClick: () => window.open(`${globals.apiUrl}/devices/${device.id}/file`),
                tooltip: 'Download conf.sys'
            },
            {
                icon: icons.upload,
                onClick: () => setOpenDrawer('uploadFile'),
                tooltip: 'Upload conf.sys'
            },
            {
                icon: icons.templateCreate,
                onClick: () => setOpenDrawer('createTemplate'),
                tooltip: 'Create New Template'
            },
            {
                icon: icons.templateDownload,
                onClick: () => window.open(`${globals.apiUrl}/devices/${device.id}/template`, '_blank'),
                tooltip: 'Download Template'
            }
        );
    }

    let DrawerContent;
    if (drawer === 'createTemplate') {
        DrawerContent = (
            <CreateTemplate id={device.id} closeDrawer={closeDrawer}/>
        );
    } else if (drawer === 'editDevice') {
        DrawerContent = (
            <EditDevice id={device.id} installationZones={installationZones} closeDrawer={closeDrawer}/>
        );
    } else if (drawer === 'knownProblem') {
        DrawerContent = (
            <KnownProblem id={device.id} closeDrawer={closeDrawer}/>
        );
    } else if (drawer === 'replaceDevice') {
        DrawerContent = (
            <ReplaceDevice id={device.id} onSave={closeDrawer}/>
        );
    } else if (drawer === 'showEquipment') {
        DrawerContent = (
            <ShowEquipment disableButtons id={device.equipment_id}/>
        );
    } else if (drawer === 'uploadFile') {
        DrawerContent = (
            <UploadConfFile deviceId={device.id} onSave={closeDrawer}/>
        );
    }

    return (
        <React.Fragment>
            <Panel buttons={buttons}>
                <SimpleList content={properties}/>
            </Panel>
            <Drawer big={drawer === 'showEquipment'} onClose={closeDrawer} open={!!drawer}>
                {DrawerContent}
            </Drawer>
        </React.Fragment>
    );
};

export default Component;
