import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/equipment';
import EditEquipment from 'Pages/Equipment/Edit';
import NewEquipment from 'Pages/Equipment/New';
import ShowEquipment from 'Pages/Equipment/Show';
import EditAttachment from 'Pages/EquipmentAttachments/Edit';
import NewAttachment from 'Pages/EquipmentAttachments/New';
import ShowAttachment from 'Pages/EquipmentAttachments/Show';
import EditReading from 'Pages/Readings/Edit';
import NewReading from 'Pages/Readings/New';
import ShowReading from 'Pages/Readings/Show';
import React from 'react';
import { useSelector } from 'react-redux';
import columns from 'utils/columns/equipments';

const Component = props => {

    const entityType = useSelector(state => state.drawerView.entity);
    const equipmentProps = useSelector(state => state.equipments);
    const stateProps = {...equipmentProps, entityType};

    const { useChangeSearch, useChangeSort, useFetchAll, useToggleAll, useToggleOne } = actions;
    const stateActions = ({
        changeSearch: useChangeSearch(),
        changeSort: useChangeSort(),
        fetchData: useFetchAll(),
        toggleAll: useToggleAll(),
        toggleOne: event => {
            event.stopPropagation();
            return useToggleOne()(event.target.name);
        }
    });

    let editComponent = EditEquipment;
    let newComponent = NewEquipment;
    let showComponent = ShowEquipment;

    if (stateProps.entityType === 'ATTACHMENT') {
        editComponent = EditAttachment;
        newComponent = NewAttachment;
        showComponent = ShowAttachment;
    }

    if (stateProps.entityType === 'READING') {
        editComponent = EditReading;
        newComponent = NewReading;
        showComponent = ShowReading;
    }

    return (
        <EntitiesView
            big
            columns={columns}
            editComponent={editComponent}
            newComponent={newComponent}
            showComponent={showComponent}
            {...stateProps}
            {...stateActions}
            {...props}
        />
    );
};

export default Component;

