import React from 'react';
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, ListSubheader, AppBar, IconButton, Toolbar, styled, alpha } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closeSidebar } from 'ducks/ui';
import Icon from 'Components/Icon';
import PropTypes from 'prop-types';
import { HEADER_HEIGHT, SPACER_MINI } from 'utils/ui/constants.js';
import history from '~/history';

const drawerWidth = 260;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        position: 'relative',
        top: `${HEADER_HEIGHT - SPACER_MINI}px`,
    }
}));

const StyledList = styled(List)({
    width: 'auto',
    overflowY: 'auto'
});

const StyledListItem = styled(ListItemIcon)({
    width: 16
});

const navigate = (event) => history.push(event.currentTarget.dataset.url);

const renderSubsection = ({ icon, text, url }) => (
    <ListItem button key={url} data-url={url} onClick={navigate}>
        <StyledListItem>
            <Icon icon={icon} pull="right" />
        </StyledListItem>
        <ListItemText primary={text} />
    </ListItem>
);

const renderSections = (sections) =>
    sections.map(({ name, subsections }, index) => (
        <React.Fragment key={index}>
            <StyledList
                dense
                subheader={<ListSubheader component="div" disableSticky>{name}</ListSubheader>}
            >
                {subsections.map(renderSubsection)}
            </StyledList>
            {index === sections.length - 1 ? null : <Divider />}
        </React.Fragment>
    ));

const Sidebar = ({ sections }) => {
    const dispatch = useDispatch();
    const showSidebar = useSelector((state) => state.ui.showSidebar);

    const closeBar = () => dispatch(closeSidebar());

    return (
        <StyledDrawer
            onClose={closeBar}
            open={showSidebar}
            onMouseLeave={closeBar}
        >
            <div
                onClick={closeBar}
                onKeyDown={closeBar}
                role="button"
                tabIndex={0}
            >
                <StyledList>{renderSections(sections)}</StyledList>
            </div>
        </StyledDrawer>
    );
};

Sidebar.propTypes = {
    sections: PropTypes.array.isRequired,
};

export default Sidebar;
