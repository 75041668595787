import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/readings';
import React from 'react';
import { useSelector } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {

    const metadatas = useSelector(state => selectorMetadatas.metadataFromReading(state));
    const model = useSelector(state => state.readings.models[state.drawerView.entityId]);
    const submitting = useSelector(state => state.readings.submitting);
    const stateProps = { metadatas, model, submitting };

    const actionProps = ({
        create: actions.create,
        update: actions.update
    });

    const formSchema = [
        {
            type: 'text',
            label: 'Name\x2a',
            name: 'name',
            validate: [validators.required]
        },
        ...metadataFields(metadatas, model),
        {
            type: 'checkBox',
            label: 'Activate Measurement?',
            name: 'activate_measurement'
        }
    ];

    return (
        <EntityManageView
            formSchema={formSchema}
            formInitialValues={model}
            label="Edit Reading"
            {...stateProps}
            {...actionProps}
            {...props}
        />
    );
};

export default Component;
