import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/equipmentAttachments';
import React from 'react';
import { useSelector } from 'react-redux';
import validators from 'utils/forms/validators';

const Component = props => {

    const equipmentId = useSelector(state => state.drawerView.entityId);
    const submitting = useSelector(state => state.equipmentAttachments.submitting);
    const stateProps = {equipmentId, submitting};

    const stateActions = ({
        create: actions.useCreate(),
        update: actions.useUpdate()
    });

    const formSchema = [
        {
            label: 'Name\x2a',
            name: 'name',
            type: 'text',
            validate: [validators.required]
        },
        {
            label: 'Comment',
            name: 'comment',
            type: 'textArea'
        },
        {
            accept: 'application/pdf,application/vnd.ms-excel,application/zip,image/jpg,image/png,text/plain',
            label: 'File\x2a',
            name: 'file',
            type: 'file',
            validate: [validators.required]
        }
    ];

    return (
        <EntityManageView
            {...stateProps}
            {...stateActions}
            {...props}
            extraData={{ equipment_id: stateProps.equipmentId }}
            formSchema={formSchema}
            label="New Attachment"
        />
    );
};

export default Component;
