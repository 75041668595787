import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/users';
import { sortBy } from 'lodash';
import React from 'react';
import validators from 'utils/forms/validators';
import { useSelector } from 'react-redux';

const Component = props => {

    const organizations = useSelector(state => state.auth.organizations);
    const submitting = useSelector(state => state.users.submitting);
    const stateProps = { organizations, submitting };

    const { useCreate } = actions;
    const actionProps = {
        create: useCreate()
    };

    const formSchema = [
        {
            label: 'Name\x2a',
            name: 'name',
            type: 'text',
            validate: [validators.required]
        },
        {
            label: 'Email\x2a',
            name: 'email',
            type: 'text',
            validate: [validators.required]
        },
        {
            formData: sortBy(organizations, 'name'),
            label: 'Organization\x2a',
            name: 'organization_id',
            type: 'select',
            validate: [validators.required]
        },
        {
            type: 'checkBox',
            label: 'Enabled?',
            name: 'enabled'
        }
    ];

    return (
        <EntityManageView
            formSchema={formSchema}
            label="Create User"
            {...stateProps}
            {...actionProps}
            {...props}
        />
    );
};

export default Component;
