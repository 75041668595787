import React, { useState } from 'react';
import { Avatar, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { openMenu } from 'ducks/ui';
import getInitials from 'utils/getInitials';
import Menu from 'Components/Header/Menu';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/system';

const SectionDesktop = styled('div')({
    display: 'flex',
});

const AvatarStyled = styled(Avatar)(({ theme }) => ({
    height: 44,
    width: 44,
}));

const AvatarText = styled(Avatar)(({ theme, showMenu }) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.white,
    cursor: 'pointer',
    height: 44,
    width: 44,
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 0.65),
        color: alpha(theme.palette.primary.white, 0.65),
    },
    ...(showMenu && {
        backgroundColor: alpha(theme.palette.primary.light, 0.65),
        color: alpha(theme.palette.primary.white, 0.65),
    }),
}));

const Component = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const showMenu = useSelector(state => state.ui.showMenu);
    const currentUser = {} || useSelector(state => state.users.models[state.auth.currentUserId]);
    const dispatch = useDispatch();

    const displayMenu = event => {
        setAnchorEl(event.currentTarget);
        dispatch(openMenu());
    };

    return (
        <SectionDesktop>
            <IconButton onClick={displayMenu} onMouseEnter={displayMenu}>
                <AvatarStyled>
                    <AvatarText showMenu={showMenu}>{getInitials(currentUser.name)}</AvatarText>
                </AvatarStyled>
            </IconButton>
            <Menu anchorEl={anchorEl} />
        </SectionDesktop>
    );
};

export default Component;
