import Table from 'Components/SimpleTable';
import React from 'react';
import { useSelector } from 'react-redux';
import attachmentsSelector from 'selectors/equipmentAttachments';
import getReadableFileSize from 'utils/getReadableFileSize';

const columns = [
    { label: 'Name', dataKey: 'name' },
    { label: 'Type', dataKey: 'mime_type' },
    { label: 'Size', dataKey: 'size', transform: attachment => getReadableFileSize(attachment.size) }
];

const Attachments = props => {
    const { openAttachment } = props;

    const equipmentId = useSelector(state => props.equipmentId || state.drawerView.entityId);
    const attachments = useSelector(state => attachmentsSelector(state, equipmentId));

    return (
        <Table
            columns={columns}
            onRowClick={openAttachment}
            rows={attachments}
        />
    );
};

export default Attachments;
