import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as measurementActions } from 'ducks/measurements';
import React from 'react';
import { useSelector } from 'react-redux';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields, serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { device, measurement } = props;
    const { closeDrawer } = props;

    const formSchema = [];

    const metadatas = useSelector(state => selectorMetadatas.metadataFromMeasurement(state, props));
    const reading = useSelector(state => state.readings.models[props.measurement.reading_id]);
    const submitting = useSelector(state => state.measurements.submitting);

    if (['WiseInput', 'WiseCurrent'].includes(device.equipment_category_name)) {
        formSchema.push({
            label: 'Name\x2a',
            name: 'name',
            type: 'text',
            validate: [validators.required]
        });
    }

    if (measurement.a_formula) {
        formSchema.push({
            disabled: true,
            label: 'a_formula\x2a',
            name: 'a_formula',
            type: 'text',
            validate: [validators.required]
        });
    }

    let metadataSchemas = metadataFields(metadatas);
    if (device.equipment_category_name === 'WiseCurrent' && reading.reading_kind_name.startsWith('EA+')) {
        metadataSchemas = [];
    }
    formSchema.push(...metadataSchemas);

    const update = measurementActions.useUpdate();
    const onSubmit = values => {
        update(
            measurement.id,
            serializeForm(values, formSchema, measurement),
            closeDrawer
        );
    };

    return (
        <FormViewer label="Edit Measurement">
            <Form
                formSchema={formSchema}
                formInitialValues={measurement}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;
