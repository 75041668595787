import cn from '~/utils/cn';
import { cva } from 'class-variance-authority';
import * as React from 'react';

const alertVariants = cva(
        'tw-relative tw-w-full tw-rounded-lg tw-border tw-p-4 [&>svg~*]:tw-pl-7 [&>svg+div]:tw-translate-y-[-3px] [&>svg]:tw-absolute [&>svg]:tw-left-4 [&>svg]:tw-top-4',
        {
            variants: {
                variant: {
                    default: 'tw-bg-background tw-text-foreground [&>svg]:tw-text-foreground',
                    destructive:
                            'tw-bg-destructive/30 tw-text-default dark:tw-border-destructive [&>svg]:tw-text-destructive [&>h5]:tw-text-destructive',
                    success:
                            'tw-bg-green-600/20 tw-text-default dark:tw-border-secondary [&>svg]:tw-text-secondary [&>h5]:tw-text-secondary',
                    info:
                            'tw-bg-info/20 tw-text-default dark:tw-border-info-foreground [&>svg]:tw-text-info-foreground [&>h5]:tw-text-info-foreground',
                    warning:
                            'tw-bg-amber-400/20 tw-text-default dark:tw-border-amber-400 [&>svg]:tw-text-amber-800 [&>h5]:tw-text-amber-800'
                }
            },
            defaultVariants: {
                variant: 'default'
            }
        }
);

const Alert = React.forwardRef(({ className, variant, ...props }, ref) => (
        <div
                ref={ref}
                role="alert"
                className={cn(alertVariants({ variant }), className)}
                {...props} />
));
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef(({ className, ...props }, ref) => (
        <h5
                ref={ref}
                className={cn(' tw-py-1 tw-font-medium tw-leading-none tw-tracking-tight', className)}
                {...props} />
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef(({ className, ...props }, ref) => (
        <div
                ref={ref}
                className={cn('tw-text-sm [&_p]:tw-leading-relaxed', className)}
                {...props} />
));
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertTitle, AlertDescription };
