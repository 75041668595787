import Grid from '@mui/material/Grid2';
import ExpandedPanel from 'Components/ExpandedPanel';
import { actions as drawerViewActions } from 'ducks/drawerView';
import { actions as equipmentActions } from 'ducks/equipment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import readingSelectors from 'selectors/readings';
import { HEADER_HEIGHT, SPACER_MINI, TABS_HEIGHT } from 'utils/ui/constants.js';
import Attachments from './Attachments';
import Details from './Details';
import Devices from './Devices';
import Readings from './Readings';
import Toolbar from './Toolbar';
import { styled } from '@mui/material/styles';

const MainContainerGrid = styled(Grid)(({theme}) => ({
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    backgroundColor: theme.palette.backgrounds.color.main,
    padding: '0px 10px 0px',
    width: '100%',
}));

const HeaderContainerGrid = styled(Grid)({
    height: `${TABS_HEIGHT + SPACER_MINI}px`,
    width: '100%',
});

const BottomContainerGrid = styled(Grid)({
    height: `calc(100% - ${TABS_HEIGHT + SPACER_MINI + SPACER_MINI}px)`,
    paddingBottom: 5,
    overflowY: 'auto',
});

const EquipmentPage = props => {
    const { classes, disableButtons = false } = props;

    const id = useSelector(state => props.id || state.drawerView.entityId);
    const model = useSelector(state => state.equipments.models[id]);
    const readings = useSelector( state => readingSelectors.readingsByEquipmentId(state, id))

    const dispatch = useDispatch();
    const viewEntity = (entityId, entity) => dispatch(drawerViewActions.viewEntity(entityId, entity));

    useEffect(() => {
        dispatch(equipmentActions.fetchOne(id));
    }, [id]);

    const openAttachment = attachmentId => viewEntity(attachmentId, 'ATTACHMENT');

    let ReadingsList;
    if (model.can_have_readings) {
        const onRowClick = id => viewEntity(id, 'READING');

        ReadingsList = (
            <ExpandedPanel id="readings" header="Readings">
                <Readings onRowClick={onRowClick} readings={readings}/>
            </ExpandedPanel>
        );
    }

    return (
        <MainContainerGrid container>
            <HeaderContainerGrid>
                <Toolbar disableButtons={disableButtons} equipment={model}/>
            </HeaderContainerGrid>
            <BottomContainerGrid >
                <ExpandedPanel id="properties" header="Details">
                    <Details model={model}/>
                </ExpandedPanel>
                {ReadingsList}
                <ExpandedPanel id="attachments" header="Attachments">
                    <Attachments equipmentId={model.id} openAttachment={openAttachment}/>
                </ExpandedPanel>
                <ExpandedPanel id="devices" header="Devices">
                    <Devices devices={model.devices}/>
                </ExpandedPanel>
            </BottomContainerGrid>
        </MainContainerGrid>
    );
};

export default EquipmentPage;
