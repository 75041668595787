import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as measurementActions } from 'ducks/measurements';
import React from 'react';
import { useSelector } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';
import sortMeasurements from 'utils/sortMeasurements';

const Component = props => {
    const { closeDrawer } = props;

    const device = useSelector(state => state.devices.models[measurement.device_id]);
    const measurement = useSelector(state => state.measurements.models[props.measurementId]);
    const devices = useSelector(state => state.devices.models);
    const measurements = useSelector(state => state.measurements.models);
    const submitting = useSelector(state => state.measurements.submitting);

    const formSchema = [];
    const usedPortIds = [];
    Object.values(measurements).forEach(measurement => {
        if (measurement.port_id && devices[measurement.device_id].parent_id === device.parent_id) {
            usedPortIds.push(measurement.port_id);
        }
    });

    const availablePorts = Object.values(measurements).filter(measurement => measurement.device_id === device.parent_id && !usedPortIds.includes(measurement.id)).sort(sortMeasurements);

    formSchema.push({
        formData: availablePorts,
        label: 'Connected Port\x2a',
        name: 'port_id',
        type: 'select',
        validate: [validators.required]
    });

    const update = measurementActions.useUpdate();
    const onSubmit = values => {
        const data = { ...serializeForm(values, formSchema, measurement), stopped_at: null };

        update(
            measurement.id,
            data,
            closeDrawer
        );
    };

    return (
        <FormViewer label="Restart Measurement">
            <Form
                formSchema={formSchema}
                formInitialValues={measurement}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;
