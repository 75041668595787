import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import Loading from 'Components/ScopedLoader';
import { actions as deviceActions } from 'ducks/devices';
import { loadBuilding } from 'ducks/loadBuilding';
import Building from './Building';
import BuildingList from './BuildingList';
import Device from './Device';
import Tree from './Tree';
import { HEADER_HEIGHT } from 'utils/ui/constants';

const LeftContainer = styled(Grid)(({ theme }) => ({
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    backgroundColor: theme.palette.backgrounds.color.main
}));

const RightContainer = styled(Grid)(({ theme }) => ({
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
}));

const Component = (props) => {
    const mode = props.match.params.deviceId ? 'device' : 'building';
    const panelOpen = useSelector(state => state.ui.showBuildings);
    const section = useSelector(state => props.match.params.section);
    const device = useSelector(state => state.devices.models[props.match.params.deviceId]);
    const buildingId = useSelector(state => mode === 'building' ? props.match.params.buildingId : (device && device.building_id));
    const validBuilding = useSelector(state => mode === 'building' ? !!state.buildings.models[props.match.params.buildingId] : !!state.buildings.models[buildingId]);
    const loaded = useSelector(state => mode === 'building' ? state.loadedBuildings[props.match.params.buildingId] : state.loadedBuildings[buildingId]);
    const deviceId = useSelector(state => mode === 'building' ? null : props.match.params.deviceId);
    const validDevice = useSelector(state => mode === 'building' ? null : !!device);

    const dispatch = useDispatch();
    const actionsProps = {
        loadBuilding: id => dispatch(loadBuilding(id)),
        loadDevice: id => dispatch(deviceActions.fetchOne(id))
    };

    useEffect(() => {
        if (mode === 'building' && !loaded) {
            actionsProps.loadBuilding(buildingId);
        } else if (mode === 'device' && !validDevice) {
            actionsProps.loadDevice(deviceId);
        } else if (mode === 'device' && !loaded) {
            actionsProps.loadBuilding(buildingId);
        }
    }, [mode, buildingId, deviceId]);

    if (!loaded) {
        return <Loading/>;
    }

    if (!validBuilding) {
        return <div>Invalid building</div>;
    }

    if (deviceId && !validDevice) {
        return <div>Invalid device</div>;
    }

    let tree = <Tree buildingId={buildingId} deviceId={deviceId}/>;
    if (panelOpen) {
        tree = null;
    }

    const Container = deviceId ?
        <Device deviceId={deviceId} section={section}/> :
        <Building buildingId={buildingId} section={section}/>;

    return (
        <>
            <LeftContainer  size={3}>
                <BuildingList buildingId={buildingId}/>
                {tree}
            </LeftContainer>
            <RightContainer size={9}>
                {Container}
            </RightContainer>
        </>
    );
};

export default Component;
