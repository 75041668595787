import axios from 'axios';
import { takeLatest } from 'redux-saga/effects';
import { apiRequest } from 'utils/request';
import history from '~/history';
import { FULFILLED, LOGIN, LOGOUT, REJECTED } from './actionTypes';

export const logout = () => ({
    type: LOGOUT
});

// reducer
const INITIAL_STATE = {
    currentUserId: null,
    organizations: []
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LOGIN + FULFILLED:
            return {
                currentUserId: payload.id,
                organizations: payload.organizations
            };

        default:
            return state;
    }
};

function* logoutSaga() {
    try {
        yield apiRequest('/logout', 'POST');
        delete axios.defaults.headers.common['Authorization'];
        history.replace('/login');
    } catch (error) {
        delete axios.defaults.headers.common['Authorization'];
        history.replace('/login');
    }
}

function* watchLogout() {
    yield takeLatest(LOGOUT, logoutSaga);
}

const sagas = [
    watchLogout
];

export { sagas };
