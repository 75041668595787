import { blue as blueMui, green, orange, red } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import merge from 'lodash/merge';

// TODO: Overall theme should be refactored so stylings start including theme.palette.augmentColor.
// https://github.com/mui-org/material-ui/issues/12390

const rawTheme = createTheme({
    palette: {
        primary: {
            light: '#8BC53F',
            main: '#224C4F',
            dark: '#1A1D24',
            white: '#EEEEEE'
        },
        secondary: {
            light: '#A2D0FF',
            main: '#8BC53F',
            dark: '#50B87F',
            white: '#EEEEEE'
        },
        pins: {
            down: '#FF5F60',
            ignore: '#6EB3E3',
            known_problem: '#A460DC',
            ok: '#8BC53F',
            problem: '#F59E24',
            unknown: '#AAAAAA'
        },
        type: 'light',
        background: {
            default: '#FFFFFF'
        }
    },
    typography: {
        fontFamily: ['Gotham', 'Open Sans', 'Helvetica', 'Arial', 'sans-serif'],
    }
});

const blue = { color: {main: blueMui['600'] }};
rawTheme.palette.augmentColor(blue);

const ok = { color: { main: alpha(green['500'], 0.75) }};
rawTheme.palette.augmentColor(ok);

const warning = { color: { main: alpha(orange['500'], 0.75) }};
rawTheme.palette.augmentColor(warning);

const down = { color: { main: alpha(red['500'], 0.75) }};
rawTheme.palette.augmentColor(down);

const backgrounds = {
    color: {
        light: alpha(rawTheme.palette.primary.main, 0.2),
        main: alpha(rawTheme.palette.primary.main, 0.4),
        dark: alpha(rawTheme.palette.primary.main, 0.7)
    }
};
rawTheme.palette.augmentColor(backgrounds);

const theme = merge(rawTheme, {
    palette: { blue, down, ok, warning, backgrounds }
});

export default theme;
