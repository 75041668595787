import EntityView from 'Components/EntityView';
import { actions } from 'ducks/metadataRules';
import { capitalize } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { unixToDate } from 'utils/date';
import getUserName from 'utils/getUserName';

const properties = model => [
    { label: 'ID', content: model.id },
    { label: 'Equipment category', content: model.equipment_category_name },
    { label: 'Entity', content: capitalize(model.entity) },
    { label: 'Metadata', content: model.metadata_names.sort().map(name => <div key={name}>{name}</div>) },
    { label: 'Modifiable?', content: model.modifiable ? 'Yes' : 'No' },
    { label: 'Creation', content: `${getUserName(model.created_by)} @ ${unixToDate(model.created_at)}` },
    { label: 'Update', content: `${getUserName(model.updated_by)} @ ${unixToDate(model.updated_at)}` }
];

const Component = props => {

    const loading = useSelector(state => state.metadataRules.loading);
    const model = useSelector(state => state.metadataRules.models[state.drawerView.entityId]);
    const root = useSelector(state => state.users.models[state.auth.currentUserId].root);
    const stateProps = { loading, model, root };

    const actionProps = ({
        remove: actions.remove
    });

    return <EntityView
        disableDelete
        disableEdit
        properties={properties}
        tabsHeader={'Metadata Rule details'}
        {...stateProps}
        {...actionProps}
        {...props}
    />;
};

export default Component;
