import React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

const Container = styled('div')({
    height: '100%',
    width: '100%',
    padding: '20px',
});

const Component = () => (
    <Container>
        <LinearProgress />
    </Container>
);

export default Component;
