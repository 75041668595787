import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import IconButton from 'Components/Buttons/IconButton';
import { refreshBuilding } from 'ducks/loadBuilding';
import { collapseTree, toggleProblems } from 'ducks/ui';
import icons from 'utils/ui/icons';
import history from '~/history';
import { SPACER_MINI, TREE_HEADER_HEIGHT } from 'utils/ui/constants';

const ToolbarContainer = styled('div')({
    lineHeight: '34px'
});

const Component = ({ buildingId }) => {
    const devices = useSelector(state => state.devices.models);
    const dispatch = useDispatch();

    const actionProps = {
        collapseTree: () => dispatch(collapseTree()),
        refreshBuilding: id => dispatch(refreshBuilding(id)),
        toggleProblems: (buildingId, devices) => dispatch(toggleProblems(buildingId, devices))
    };

    const buttons = [
        { icon: icons.collapse, onClick: actionProps.collapseTree, tooltip: 'Collapse tree' },
        { icon: icons.warning, onClick: () => actionProps.toggleProblems(buildingId, devices), tooltip: 'Open on problems' },
        { icon: icons.buildingDetails, onClick: () => history.push(`/buildings/${buildingId}`), tooltip: 'Building details' },
        { icon: icons.refresh, onClick: () => actionProps.refreshBuilding(buildingId), tooltip: 'Refresh info' }
    ];

    return (
        <ToolbarContainer>
            {buttons.map(button => (
                <IconButton key={button.icon} {...button} />
            ))}
        </ToolbarContainer>
    );
};

export default Component;
