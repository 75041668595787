import React from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import IconButton from 'Components/Buttons/IconButton';

const StyledPaper = styled(Paper)({
    borderRadius: 0,
});

const Toolbar = styled('div')({
    backgroundColor: '#EEEEEE',
    padding: 10,
});

const Header = styled(Typography)({
    backgroundColor: '#EEEEEE',
    fontSize: '80%',
    fontWeight: 'bold',
    padding: '15px 0 15px 25px',
});

const Details = styled('div')(({ hasHeader }) => ({
    padding: 20,
    paddingTop: hasHeader ? 0 : 10,
}));

const SmallFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
        fontSize: '0.75rem',
    },
}));

const Component = ({ buttons = [], children, header, realtime, setRealtime }) => {
    return (
        <StyledPaper>
            {(buttons.length > 0 || setRealtime) && (
                <Toolbar>
                    {setRealtime ? (
                        <SmallFormControlLabel
                            control={
                                <Switch
                                    checked={realtime}
                                    name="checked"
                                    onChange={(event) => setRealtime(event.target.checked)}
                                    size="small"
                                />
                            }
                            label="Pool Data"
                        />
                    ) : (
                        buttons.map((button) => <IconButton key={button.icon} {...button} />)
                    )}
                </Toolbar>
            )}
            {header && <Header component="h6" variant="h6">{header}</Header>}
            <Details hasHeader={!!header}>{children}</Details>
        </StyledPaper>
    );
};

export default Component;
