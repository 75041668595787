import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/readings';
import { sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import formSelectors from 'selectors/form';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {

    const equipments = useSelector(state => state.equipments.models);
    const formValues = useSelector(state => formSelectors.getFormValues(state));
    const metadatas = useSelector(state => selectorMetadatas.metadataFromReading(state));
    const protocols = useSelector(state => state.protocols.models);
    const readingKinds = useSelector(state => state.bootstrap.reading_kinds);
    const equipmentId = useSelector(state => state.drawerView.entityId);
    const submitting = useSelector(state => state.readings.submitting);
    const stateProps = { equipments, formValues, metadatas, protocols, readingKinds, equipmentId, submitting };


    const actionProps = ({
        change: change,
        create: actions.useCreate(),
        update: actions.useUpdate()
    });

    const dispatch = useDispatch();
    useEffect(() => {
        const readingKind = readingKinds.find(readingKind => readingKind.id === formValues.reading_kind_id);

        if (readingKind) {
            dispatch(actionProps.change('form', 'name', readingKind.name));
        }
    }, [formValues.reading_kind_id]);

    const formSchema = [];

    if (equipments[equipmentId].out_protocol_id === 6) {
        formSchema.push(
            {
                type: 'select',
                label: 'Reading Kind\x2a',
                name: 'reading_kind_id',
                validate: [validators.required],
                formData: sortBy(readingKinds, 'name')
            },
            {
                type: 'text',
                label: 'Name\x2a (avoid changing unless absolutely necessary)',
                name: 'name',
                validate: [validators.required]
            }
        );
    } else {
        formSchema.push(
            {
                type: 'text',
                label: 'Name\x2a (please use standardized names, eg: DI/DO/Port)',
                name: 'name',
                validate: [validators.required]
            }
        );
    }

    formSchema.push(...metadataFields(metadatas));

    return (
        <EntityManageView
            extraData={{ equipment_id: equipmentId }}
            formSchema={formSchema}
            label="New Reading"
            {...stateProps}
            {...actionProps}
            {...props}
        />
    );
};

export default Component;
