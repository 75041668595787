import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions } from 'ducks/installationPictures';
import React from 'react';
import { useSelector } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';

const Component = props => {
    const { installationPicture } = props;
    const { closeDrawer } = props;

    const submitting = useSelector(state => state.installationPictures.submitting);

    let formSchema = [
        {
            type: 'textArea',
            label: 'Comment',
            name: 'comment',
            rows: 4
        }
    ];

    const update = actions.useUpdate();
    const onSubmit = values => update(
        installationPicture.id,
        serializeForm(values, formSchema, installationPicture),
        () => closeDrawer()
    );

    return (
        <FormViewer label="Edit Picture">
            <Form
                formSchema={formSchema}
                formInitialValues={installationPicture}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;
