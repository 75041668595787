import Table from 'Components/SimpleTable';
import { actions as logActions } from 'ducks/logs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import statusToColor from 'utils/statusToColor';
import styleTime from 'utils/styleTime';

const
    collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }),
    transformDataValue = ({ last_acquisition_value: value }) => value === null || value === undefined ? 'N/A' : value,
    transformLastAcquisition = ({ last_acquisition_timestamp }) => styleTime(last_acquisition_timestamp),
    transformStatus = ({ status }) => <span style={{ color: statusToColor(status) }}>{status}</span>;

const columns = [
    { label: 'Name', dataKey: 'name' },
    { label: 'Count', dataKey: 'acquisition_count' },
    { label: 'Last Value', dataKey: 'last_acquisition_value', transform: transformDataValue },
    { label: 'Last Acquisition', dataKey: 'last_acquisition_timestamp', transform: transformLastAcquisition },
    { label: 'Status', dataKey: 'status', transform: transformStatus }
];

const Component = props => {
    const { device } = props;

    const loading = useSelector(state => state.logs.loading);
    const rows = useSelector(state => state.logs.data);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(logActions.startPoolingDataLogs(device.id));

        return () => dispatch(logActions.stopPoolingDataLogs(device.id));
    }, []);

    const sortedRows = rows.sort((a, b) => collator.compare(a.name, b.name));

    return (
        <Table columns={columns} loading={loading} rows={sortedRows}/>
    );
};

export default Component;
