import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";

const StyledTextField = styled(TextField)({
    width: "100%",
    marginTop: 16,
    marginBottom: 8,
    "& input": {
        fontSize: "0.65rem"
    },
    "& .MuiInputBase-root": {
        borderRadius: 0,
        height: 30,
        paddingBottom: 15
    },
    "& .MuiInputLabel-root": {
        transform: "translate(12px, -15px) scale(0.75)"
    }
});

const CustomDateTimeField = ({ disabled, input, label, meta, ...rest }) => {
    if (typeof input.value !== "string") {
        input.value = moment.unix(input.value).format("YYYY-MM-DDTHH:mm");
    }

    const onKeyDown = event => event.preventDefault();

    return (
        <StyledTextField
            {...rest}
            disabled={disabled}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            id="datetime-local"
            onKeyDown={onKeyDown}
            label={label}
            type="datetime-local"
            variant="filled"
            inputProps={input}
            InputLabelProps={{ shrink: true }}
        />
    );
};

CustomDateTimeField.propTypes = {
    input: PropTypes.object, // Injected by Field from Redux forms
    label: PropTypes.string,
    meta: PropTypes.object // Injected by Field from Redux forms
};

export default CustomDateTimeField;
