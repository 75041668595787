import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import Map from 'Components/Maps';
import { actions } from 'ducks/buildings';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Marker from './Marker';
import Search from './Search';

const buildingStateToMarkers = (ids, models) => {
    return ids.reduce((acc, id) => {
        if (models[id].status !== 'disabled') {
            acc.push({
                lat: models[id].latitude,
                lng: models[id].longitude,
                Component: Marker,
                building: models[id]
            });
        }

        return acc;
    }, []);
};

const MapContainer = styled(Grid)({
    height: 'calc(100vh - 64px)',
    width: '100%',
});

const Component = props => {
    const { defaultCenter, defaultZoom } = props

    const ids = useSelector(state => state.buildings.ids);
    const models = useSelector(state => state.buildings.models);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.startPoolingBuildings());
        return () => dispatch(actions.stopPoolingBuildings());
    }, []);

    const markers = buildingStateToMarkers(ids, models);

    return (
        <MapContainer>
            <Map
                defaultCenter={defaultCenter}
                defaultZoom={defaultZoom}
                markers={markers}
            >
                <Search/>
            </Map>
        </MapContainer>
    );
};

Component.defaultProps = {
    defaultCenter: { lat: 38.7390071088303, lng: -9.10513977732148 },
    defaultZoom: 6
};

export default Component;
