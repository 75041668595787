import IconButton from 'Components/Buttons/IconButton';
import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import Table from 'Components/SimpleTable';
import { actions } from 'ducks/installationZones';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import icons from 'utils/ui/icons';
import EditZone from './Edit';
import NewZone from './New';

const installationZoneProperties = [
    { label: 'Full Path', dataKey: 'full_path' },
    { label: 'Actions', dataKey: 'actions' }
];

const Component = props => {
    const { buildingId } = props;

    const installationZonesUnfiltered = useSelector(state => state.installationZones.models);
    const submitting = useSelector(state => state.installationZones.submitting);

    const installationZones = Object.values(installationZonesUnfiltered).filter(zone => zone.building_id === buildingId);

    const { fetchAll, remove } = {
        fetchAll: actions.useFetchAll(),
        remove: actions.useRemove()
    };

    const [drawer, setOpenDrawer] = useState({});
    const closeDrawer = () => setOpenDrawer({});

    const onEdit = () => {
        fetchAll({ building_id: buildingId }, null);
        closeDrawer();
    };

    const buttons = [
        { icon: icons.addEntity, onClick: () => setOpenDrawer({ view: 'newZone', installationZone: {} }), tooltip: 'Add Root Zone' }
    ];

    const rows = sortBy(installationZones, 'full_path').map(row => {
        const actions = [];

        actions.push(
            <IconButton icon={icons.addEntity} key="create" onClick={() => setOpenDrawer({ view: 'newZone', installationZone: row })} tooltip="Add sub Zone"/>,
            <IconButton icon={icons.edit} key="edit" onClick={() => setOpenDrawer({ view: 'editZone', installationZone: row })} tooltip="Edit Zone"/>,
            <IconButton
                confirmationText="Are you sure you want to remove this zone?"
                disabled={row.children_count !== 0}
                icon={icons.destroy}
                key="remove"
                onClick={() => remove(row.id, null)}
                submitting={submitting}
                tooltip="Remove Zone"
            />
        );

        row.actions = (
            <React.Fragment>
                {actions}
            </React.Fragment>
        );
        return row;
    });

    let DrawerContent;
    switch (drawer.view) {
        case 'newZone' :
            DrawerContent = <NewZone buildingId={buildingId} parentId={drawer.installationZone.id} onSave={closeDrawer}/>;
            break;
        case 'editZone' :
            DrawerContent = <EditZone installationZone={drawer.installationZone} onSave={onEdit}/>;
    }

    return (
        <React.Fragment>
            <Panel buttons={buttons}>
                <Table columns={installationZoneProperties} rows={rows}/>
            </Panel>
            <Drawer onClose={closeDrawer} open={!!drawer.view}>
                {DrawerContent}
            </Drawer>
        </React.Fragment>
    );
};



export default Component;
