import FormViewer from 'Components/FormViewer';
import SimpleList from 'Components/SimpleList';
import React from 'react';
import { useSelector } from 'react-redux';

;
import { unixToDate } from 'utils/date';
import displayMetadata from 'utils/displayMetadata';
import getUserName from 'utils/getUserName';

const Component = props => {

    const measurement = useSelector(state => state.measurements.models[props.id]);

    let attributes = [
        { label: 'ID', content: measurement.id },
        { label: 'Name', content: measurement.name },
        { label: 'Circuit Name', content: measurement.circuit_name || '-' },
        { label: 'Creation', content: `${getUserName(measurement.created_by)} @ ${unixToDate(measurement.created_at)}` },
        { label: 'Update', content: `${getUserName(measurement.updated_by)} @ ${unixToDate(measurement.updated_at)}` },
        { label: 'Input', content: measurement.input_name || '-' },
        { label: 'Input Invert', content: measurement.input_invert ? 'Yes' : 'No' }
    ];

    attributes = [...attributes, ...displayMetadata(props.measurement.metadata_values)];

    return (
        <FormViewer label="Measurement Details">
            <SimpleList content={attributes}/>
        </FormViewer>
    );
};

export default Component;
