import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import React from 'react';
import { HEADER_HEIGHT} from 'utils/ui/constants';

const PaperStylingProps = PaperSize => ({
    sx: {
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        position: 'absolute',
        width: PaperSize ? '90vw' : '60vw',
        top: `${HEADER_HEIGHT}px`,
    }
});

const Component = props => {
    const { big: PaperSize, children, classes, open = true } = props;
    const { onClose = () => {} } = props;

    return (
        <Drawer
            anchor="right"
            onClose={onClose}
            open={open}
            PaperProps={PaperStylingProps(PaperSize)}
        >
            {children}
        </Drawer>
    );
};

Component.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    big: PropTypes.bool,
    children: PropTypes.node
};

export default Component;
