import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import Header from 'Components/Header';
import Loader from 'Components/Loader';
import UserFeedback from 'Components/UserFeedback';
import LoginPage from 'Pages/Login';

import icons from 'utils/ui/icons';
import history from './history';
import Routes from './Routes';

// Styled container to replace withStyles
const Container = styled(Grid)({
    position: 'relative',
    top: 64,
    bottom: 0,
    left: 0,
    right: 0,
    height: 'calc(100vh - 64px)',
});

const generateSections = (user) => {
    const catalogSections = [
        { text: 'Equipment', icon: icons.equipment, url: '/equipment' },
    ];

    if (user?.root) {
        catalogSections.push({
            text: 'Equipment Categories',
            icon: icons.equipmentCategories,
            url: '/equipment_categories',
        });
    }

    catalogSections.push({
        text: 'Metadata Rules',
        icon: icons.metadataRule,
        url: '/metadata_rules',
    });

    if (user?.root) {
        catalogSections.push({
            text: 'Metadata',
            icon: icons.metadata,
            url: '/metadatas',
        });
    }

    const sections = [
        {
            name: 'Buildings',
            subsections: [
                { text: 'Map', icon: icons.map, url: '/map' },
                { text: 'Table', icon: icons.table, url: '/buildings' },
            ],
        },
        {
            name: 'Catalog',
            subsections: catalogSections,
        },
    ];

    if (user?.superuser) {
        sections.push({
            name: 'WiseBoxes',
            subsections: [
                { text: 'Templates', icon: icons.templateDownload, url: '/templates' },
                { text: 'Unclaimed', icon: icons.unclaimed, url: '/unclaimed' },
            ],
        });
    }

    if (user?.root) {
        sections.push({
            name: 'Accesses',
            subsections: [{ text: 'Users', icon: icons.users, url: '/users' }],
        });
    }

    return sections;
};

const App = () => {
    const booting = useSelector((state) => state.ui.booting);
    const user = useSelector((state) => state.users.models[state.auth.currentUserId]);

    if (booting) {
        return <Loader />;
    }

    const sections = generateSections(user);

    return (
        <>
            <CssBaseline />
            <UserFeedback />
            <Router history={history}>
                <Switch>
                    <Route path="/login" component={LoginPage} />
                    <>
                        <Header sections={sections} />
                        <Container container spacing={0}>
                            <Routes />
                        </Container>
                    </>
                </Switch>
            </Router>
        </>
    );
};

export default App;
