import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/users';
import React from 'react';
import validators from 'utils/forms/validators';
import { useSelector } from 'react-redux';

const formSchema = [
    {
        type: 'text',
        label: 'Name\x2a',
        name: 'name',
        validate: [validators.required]
    },
    {
        type: 'text',
        label: 'Email\x2a',
        name: 'email',
        validate: [validators.required]
    },
    {
        type: 'password',
        label: 'Password',
        name: 'password'
    },
    {
        type: 'password',
        label: 'Password Confirmation',
        name: 'password_confirmation'
    },
    {
        type: 'checkBox',
        label: 'Enabled?',
        name: 'enabled'
    }
];

const Component = props => {
    const { update } = props;

    const model = useSelector(state => state.users.models[state.drawerView.entityId]);
    const submitting = useSelector(state => state.users.submitting);
    const stateProps = { model, submitting };

    const { useUpdate } = actions;
    const actionProps = {
        update: useUpdate()
    };

    return (
        <EntityManageView
            formSchema={formSchema}
            formInitialValues={model}
            label="Edit User"
            submitting={submitting}
            update={update}
            {...stateProps}
            {...actionProps}
        />
    );
};

export default Component;
