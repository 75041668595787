import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/wise_boxes';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import columns from 'utils/columns/wise_boxes';
import ClaimWiseBox from './Claim';



const Component = props => {

    const entityType = useSelector(state => state.drawerView.entity);
    const wise_boxes = useSelector(state => state.wise_boxes);
    const stateProps = {...wise_boxes, entityType};

    const { useChangeSearch, useChangeSort, useFetchAll, useToggleAll, useToggleOne } = actions;
    const actionProps = {
        changeSearch: useChangeSearch(),
        changeSort: useChangeSort(),
        fetchData: useFetchAll(),
        toggleAll: useToggleAll(),
        toggleOne: event => {
            event.stopPropagation();
            return useToggleOne()(event.target.name);
        }
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.startPooling(5));
        return () => dispatch(actions.stopPooling());
    }, [dispatch]);

    return (
        <EntitiesView
            columns={columns}
            showComponent={ClaimWiseBox}
            {...stateProps}
            {...actionProps}
            {...props}
        />
    );
};

export default Component;

