import { Checkbox, FormControlLabel } from "@mui/material";
import { styled } from "@mui/system";
import Icon from "Components/Icon";
import PropTypes from "prop-types";
import React from "react";
import icons from "utils/ui/icons";

const StyledFormControlLabel = styled(FormControlLabel)({
    "& .MuiFormControlLabel-label": {
        fontSize: "0.65rem"
    }
});

const CustomCheckbox = ({ disabled, input, label }) => {
    return (
        <StyledFormControlLabel
            control={
                <Checkbox
                    color="primary"
                    disabled={disabled}
                    checked={input.value}
                    checkedIcon={<Icon icon={icons.checkSquare} size="sm" />}
                    inputProps={input}
                    icon={<Icon icon={icons.square} size="sm" />}
                />
            }
            label={label}
        />
    );
};

CustomCheckbox.propTypes = {
    input: PropTypes.object, // Injected by Field from Redux forms
    label: PropTypes.string,
    meta: PropTypes.object // Injected by Field from Redux forms
};

export default CustomCheckbox;
