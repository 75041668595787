import PropertyView from 'Components/PropertyView';
import Message from 'Components/UserFeedback';
import { actions as drawerViewActions } from 'ducks/drawerView';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Component = props => {
    const { disableBack, disableEdit, disableDelete, entityType, loading, model, panelHeader, properties, tabsHeader } = props;
    const { remove } = props;

    const entityId = useSelector(state => state.drawerView.entityId);

    const attributes = model ? properties(model) : [];

    const dispatch = useDispatch();
    const { editEntity, previousState } = {
        editEntity: (entityId, entity) => dispatch(drawerViewActions.editEntity(entityId, entity)),
        previousState: () => dispatch(drawerViewActions.previousState())
    };

    let onBack = () => previousState();
    if (disableBack) {
        onBack = null;
    }

    let onDelete = (id, redirect) => () => remove(id, redirect);
    if (disableDelete) {
        onDelete = null;
    }

    let onEdit = () => editEntity(entityId, entityType);
    if (disableEdit) {
        onEdit = null;
    }

    return (
        <React.Fragment>
            <PropertyView
                tabsHeader={tabsHeader}
                panelHeader={panelHeader}
                loading={loading}
                id={entityId}
                onBack={onBack}
                onEdit={onEdit}
                onDelete={onDelete && onDelete(entityId, onBack)}
                properties={attributes}
            />
            <Message/>
        </React.Fragment>
    );
};

export default Component;

Component.propTypes = {
    entityType: PropTypes.string,
    panelHeader: PropTypes.string,
    properties: PropTypes.func,
    model: PropTypes.object,
    tabsHeader: PropTypes.string
};
