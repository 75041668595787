import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/metadataRules';
import Show from 'Pages/MetadataRules/Show';
import React from 'react';
import { useSelector } from 'react-redux';
import columns from 'utils/columns/metadataRules';

const MetadataRulesPage = props => {

    const currentUser = useSelector(state => state.auth.currentUser);
    const metadataRules = useSelector(state => state.metadataRules);
    const stateProps = {...metadataRules, currentUser};


    const { useChangeSearch, useChangeSort, useFetchAll, useToggleAll, useToggleOne } = actions;
    const actionProps = ({
        changeSearch: useChangeSearch(),
        changeSort: useChangeSort(),
        fetchData: useFetchAll(),
        toggleAll: useToggleAll(),
        toggleOne: event => {
            event.stopPropagation();
            return useToggleOne()(event.target.name);
        }
    });

    return (
        <EntitiesView
            columns={columns}
            showComponent={Show}
            {...stateProps}
            {...actionProps}
            {...props}
        />
    );
};

export default MetadataRulesPage;

