import EntitiesView from 'Components/EntitiesView';
import { actions } from 'ducks/buildings';
import Show from 'Pages/Metadata/Show';
import React from 'react';
import { useSelector } from 'react-redux';
import columns from 'utils/columns/buildings';
import history from '~/history';

const onRowClick = event => history.push(`/buildings/${event.currentTarget.dataset.id}`);

const Components = props => {

    const stateProps = useSelector(state => state.buildings);

    const { useChangeSearch, useChangeSort, useFetchAll, useToggleAll, useToggleOne } = actions;
    const actionProps = {
        changeSearch: useChangeSearch(),
        changeSort: useChangeSort(),
        fetchData: useFetchAll(),
        toggleAll: useToggleAll(),
        toggleOne: event => {
            event.stopPropagation();
            return useToggleOne()(event.target.name);
        }
    };

    return <EntitiesView
            columns={columns}
            onRowClick={onRowClick}
            showComponent={Show}
            {...stateProps}
            {...actionProps}
            {...props}
        />;
};

export default Components;
