import { alpha } from '@mui/material/styles';

export default theme => ({
    table: {
        fontFamily: theme.typography.fontFamily
    },
    flexContainer: {
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        flex: 1,
        '& .MuiTableCell-root': {
            display: 'flex',
        }
    },
    checkboxCell: {
        padding: 0,
        width: 50
    },
    tableBody: {
        fontSize: 12
    },
    sortLabel: {
        color: 'white !important',
        '&:hover': {
            color: 'white',
        },
        '&:focus': {
            color: 'white',
        },
        '& .MuiSvgIcon-root': {
            color: 'white !important',
        },

    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: 'white !important',
        cursor: 'pointer',
        fontSize: 14,
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.75),
        },
    },
    tableRow: {
        cursor: 'pointer',
        '&:focus': {
            outline: 'none'
        },
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.light, 0.5)
        }
    },
    noClick: {
        cursor: 'initial'
    }
});
