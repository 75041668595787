import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as deviceActions } from 'ducks/devices';
import { sortBy } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { equipmentsByInProtocolId } from 'selectors/equipments';
import formSelectors from 'selectors/form';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields, serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { buildingId, installationZones, parentDeviceId } = props;
    const { onSave } = props;

    const building = useSelector(state => state.buildings.models[props.buildingId]);
    const equipments = useSelector(state => equipmentsByInProtocolId(state, props));
    const electricityMeterType = useSelector(state => state.bootstrap.equipment_types.find(equipmentType => equipmentType.name === 'Electricity Meter'));
    const formValues = useSelector(state => formSelectors.getFormValues(state));
    const metadatas = useSelector(state => selectorMetadatas.metadataFromDevice(state, props));
    const readings = useSelector(state => state.readings.models);
    const submitting = useSelector(state => state.devices.submitting);
    const templatesUnfilter = useSelector(state => state.templates.models);

    const equipmentId = formValues.equipment_id;
    let
        organizationId = building.organization_id,
        templates = Object.values(templatesUnfilter).filter(template => template.organization_id === organizationId);

    const formSchema = [
        {
            formData: equipments.filter(equipment => equipment.usable),
            label: 'Equipment\x2a',
            name: 'equipment_id',
            type: 'select',
            validate: [validators.required]
        },
        {
            formData: sortBy(installationZones, 'full_path').map(({ id, full_path }) => ({ id, name: full_path })),
            label: 'Installation Zone\x2a',
            name: 'installation_zone_id',
            type: 'select',
            validate: [validators.required]
        }
    ];

    if (equipmentId) {
        const equipment = equipments.find(equipment => equipment.id === equipmentId);

        if (!equipment.name.includes('Wise') || equipment.name === 'WiseBox' || equipment.name === 'WiseBoxNew') {
            formSchema.push({
                label: 'Name\x2a',
                name: 'name',
                type: 'text',
                validate: [validators.required]
            });

            formSchema.push({
                label: 'Description',
                name: 'description',
                rows: 8,
                type: 'textArea'
            });

            formSchema.push({
                label: 'Integration?',
                name: 'integration',
                type: 'checkBox'
            });

            if (equipment.equipment_type_id === electricityMeterType.id) {
                formSchema.push({
                    formData: [{ id: '1-phase', name: '1-phase' }, { id: '3-phase', name: '3-phase' }],
                    label: 'TI Type\x2a',
                    name: 'ti_type',
                    type: 'select',
                    validate: [validators.required]
                });

                formSchema.push({
                    label: 'TI Current\x2a',
                    name: 'ti_current',
                    type: 'number',
                    validate: [validators.required]
                });
            }

            formSchema.push(...metadataFields(metadatas));
        }
    }

    if (equipmentId) {
        const equipment = equipments.find(equipment => equipment.id === equipmentId) || {};

        if (equipment.in_protocol_id === 2) {
            const currentReadings = Object.values(readings).filter(reading => {
                return reading.equipment_id === equipmentId && reading.out_protocol_id === 6;
            });

            if (currentReadings.length) {
                formSchema.push({
                    type: 'selectMultiple',
                    label: 'Activated measurements',
                    name: 'activated_measurement_ids',
                    formData: sortBy(currentReadings, 'name')
                });
            }
        }

        if (equipment.name === 'WiseBoxNew' && templates.length) {
            formSchema.push(
                {
                    type: 'select',
                    label: 'Template',
                    name: 'template_id',
                    formData: templates.map(({ id, name }) => ({ id, name }))
                }
            );
        }
    }

    const create = deviceActions.useCreate();
    const onSubmit = values => {
        let data = {
            ...serializeForm(values, formSchema, {}),
            ...{ building_id: buildingId, parent_id: parentDeviceId }
        };

        create(data, onSave);
    };

    return (
        <FormViewer label="New device">
            <Form
                formSchema={formSchema}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};



export default Component;
