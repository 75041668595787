import React from 'react';
import { styled } from '@mui/material/styles';

const StyledImage = styled('img')({
    maxWidth: '100%',
    '&:hover': {
        cursor: 'pointer',
    },
});

const Component = ({ alt, src }) => {
    return <StyledImage alt={alt} src={src} />;
};

export default Component;
