import TabsToolbar from 'Components/TabsToolbar';
import { actions as drawerViewActions } from 'ducks/drawerView';
import { actions as equipmentActions } from 'ducks/equipment';
import React from 'react';
import { useDispatch } from 'react-redux';
import icons from 'utils/ui/icons';

const Toolbar = props => {
    const { disableButtons, equipment } = props;

    const clearEntity = drawerViewActions.clearEntity;
    const editEntity = drawerViewActions.editEntity;
    const newEntity = drawerViewActions.newEntity;
    const remove = equipmentActions.remove;

    const dispatch = useDispatch();
    const onDelete = () => dispatch(remove(
        equipment.id,
        () => dispatch(clearEntity())
    ));
    const handleEditEquipment = () => dispatch(editEntity(equipment.id, 'EQUIPMENT'));
    const handleNewReading = () => dispatch(newEntity('READING', equipment.id));
    const newAttachment = () => dispatch(newEntity('ATTACHMENT', equipment.id));

    const
        buttons = [
            { icon: icons.editConfig, onClick: handleEditEquipment, tooltip: 'Edit' },
            {
                icon: icons.trash,
                confirmationHeader: 'Delete Confirmation',
                confirmationText: 'Are you sure you want to delete this entity?',
                onClick: onDelete,
                tooltip: 'Delete'
            }
        ],
        tabs = [{ text: 'Equipment details', id: 'properties' }];

    if (equipment.can_have_readings) {
        buttons.push({ icon: icons.addEntity, onClick: handleNewReading, tooltip: 'Add reading' });
        tabs.push({ text: 'Readings', id: 'readings' });
    }

    buttons.push({ icon: icons.filePlus, onClick: newAttachment, tooltip: 'Add Attachment' });
    tabs.push({ text: 'Attachments', id: 'attachments' }, { text: 'Devices', id: 'devices' });

    return (
        <TabsToolbar buttons={disableButtons ? [] : buttons} tabs={tabs}/>
    );
};

export default Toolbar;
