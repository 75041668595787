import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import icons from 'utils/ui/icons';

const Component = props => {
    const { icon, ...rest } = props
    return <FontAwesomeIcon icon={Array.isArray(icon) ? icon : icons[icon]} {...rest} />;
}

export default Component;
