import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/equipment';
import { sortBy } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import selectorEquipmentCategories from 'selectors/equipmentCategories';
import selectorForm from 'selectors/form';
import selectorMetadatas from 'selectors/metadatas';
import { metadataFields } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {

    const equipmentCategoriesArray = useSelector(state => selectorEquipmentCategories.equipmentCategoriesArray(state));
    const equipmentTypes = useSelector(state => state.bootstrap.equipment_types);
    const formValues = useSelector(state => selectorForm.getFormValues(state));
    const metadatas = useSelector(state => selectorMetadatas.metadataFromEquipment(state));
    const stateProps = { equipmentCategoriesArray, equipmentTypes, formValues, metadatas };

    const stateActions = ({
        create: actions.useCreate(),
        update: actions.useUpdate()
    });

    const formSchema = [
        {
            type: 'text',
            label: 'Name\x2a',
            name: 'name',
            validate: [validators.required]
        },
        {
            type: 'textArea',
            label: 'Description',
            name: 'description'
        },
        {
            type: 'select',
            label: 'Equipment Category\x2a',
            name: 'equipment_category_id',
            validate: [validators.required],
            formData: equipmentCategoriesArray.filter(category => category.usable)
        },
        {
            type: 'select',
            label: 'Equipment Type\x2a',
            name: 'equipment_type_id',
            validate: [validators.required],
            formData: sortBy(equipmentTypes, 'name')
        },
        ...metadataFields(metadatas)
    ];

    return (
        <EntityManageView
            formSchema={formSchema}
            label="New Equipment"
            {...stateProps}
            {...stateActions}
            {...props}
        />
    );
};

export default Component;
