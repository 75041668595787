import Marker from 'Pages/Map/Marker/index.js';
import React, { useCallback, useEffect, useState } from 'react';

import {
    APIProvider,
    ControlPosition,
    Map,
    MapControl,
    useMap
} from '@vis.gl/react-google-maps';

const FitBoundsComponent = ({ markers }) => {
    const map = useMap(); // Get the map instance

    useEffect(() => {
        if (!map || markers.length === 0) return;

        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));

        map.fitBounds(bounds);
    }, [map, markers.length]);

    return null; // This component doesn't render anything, it just adjusts the map
};


const MarkersRenderer = props => {
    const { tooltipMutex, markers} = props;
    const { handleSetTooltipMutex } = props;

    return markers.map((marker, index) => {
        return <Marker
            key={index}
            tooltipID={index}
            tooltipMutex={tooltipMutex}
            handleSetTooltipMutex={handleSetTooltipMutex}
            position={{ lat: marker.lat, lng: marker.lng }}
            building={marker.building}
        />;
    })
}


const Maps = props => {

    const { children, defaultCenter, defaultZoom, markers } = props;

    const [tooltipMutex, setTooltipMutex] = useState(0);
    const handleSetTooltipMutex = useCallback((id) => {
        console.log('id ', id);
        setTooltipMutex(id);
    }, []);

    return (
        <APIProvider apiKey={process.env.GOOGLE_MAPS_KEY}>
            <Map
                onclick={() => handleSetTooltipMutex(0)}
                mapId={'9ae85b721c0df09e'}
                defaultZoom={defaultZoom}
                defaultCenter={defaultCenter}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
                options={{
                    fullscreenControl: false,
                    mapTypeControl: false,
                    mapTypeId: 'roadmap',
                    maxZoom: 13,
                    minZoom: 3,
                    scaleControl: false,
                    streetViewControl: false,
                    zoomControl: true
                }}
            >
                <FitBoundsComponent markers={markers}/>
                <MapControl position={ControlPosition.TOP_CENTER}>
                    {children}
                </MapControl>
                <MarkersRenderer
                    tooltipMutex={tooltipMutex}
                    handleSetTooltipMutex={handleSetTooltipMutex}
                    markers={markers}
                />
            </Map>
        </APIProvider>
    );

};
export default Maps;
