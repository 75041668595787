import React from 'react';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';

import Icon from 'Components/Icon';
import SearchInput from 'Components/SearchInput';
import icons from 'utils/ui/icons';

const TOOLBAR_HEIGHT = 48;

// Styled components for toolbar layout
const MenuGrid = styled(Grid)({
    height: TOOLBAR_HEIGHT,
    paddingLeft: 3,
    paddingTop: 5,
});

const SearchGrid = styled(Grid)({
    height: TOOLBAR_HEIGHT,
    padding: 0,
    paddingTop: 7,
});

const SummaryGrid = styled(Grid)(({ theme }) => ({
    ...theme.typography.button,
    fontSize: 10,
    height: TOOLBAR_HEIGHT,
    lineHeight: `${TOOLBAR_HEIGHT}px`,
    paddingRight: 25,
    textAlign: 'right',
    textTransform: 'none',
}));

const Summary = ({ ids, loading, models, searching }) => {
    if (loading || searching) {
        return <Icon icon={icons.spinner} spin />;
    }

    const total = Object.keys(models).length;
    return <div>{ids.length === total ? `${total} records` : `${ids.length} of ${total}`}</div>;
};

const Toolbar = ({ changeSearch, ids, loading, models, searching, searchValue, TableActions }) => {
    return (
        <>
            <MenuGrid size={1}>
                {TableActions}
            </MenuGrid>
            <SearchGrid size={10}>
                <SearchInput
                    loading={loading}
                    onSearchChange={changeSearch}
                    searching={searching}
                    searchValue={searchValue}
                />
            </SearchGrid>
            <SummaryGrid size={1}>
                <Summary ids={ids} loading={loading} models={models} searching={searching} />
            </SummaryGrid>
        </>
    );
};

export default Toolbar;
