import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as deviceActions } from 'ducks/devices';
import { isEqual, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';

const Component = props => {

    const device = useSelector(state => state.devices.models[props.id]);
    const equipment = useSelector(state => state.equipments.models);
    const submitting = useSelector(state => state.devices.submitting);

    const
        currentEquipment = equipment[device.equipment_id],
        equipmentList = Object.values(equipment)
        .filter(equipment => {
            const { equipment_category_id, id } = equipment;

            return id !== currentEquipment.id && isEqual(equipment_category_id, currentEquipment.equipment_category_id);
        });

    if (equipmentList.length) {
        const formSchema = [
            {
                type: 'select',
                label: 'New Equipment',
                name: 'equipment_id',
                formData: sortBy(equipmentList, 'name')
            }
        ];

        const dispatch = useDispatch();
        const onSubmit = values => {
            const data = serializeForm(values, formSchema);
            if (data.equipment_id) {
                dispatch(deviceActions.replace(device.id, data.equipment_id));
            }
        };

        return (
            <FormViewer label="Replace Device">
                <Form
                    formSchema={formSchema}
                    isSubmitting={submitting}
                    onSubmit={onSubmit}
                />
            </FormViewer>
        );
    } else {
        return (
            <div>
                No compatible equipment found. Unable to replace automatically, please do it manually.
            </div>
        );
    }
};

export default Component;

Component.propTypes = {
    id: PropTypes.string.isRequired
};
