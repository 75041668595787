import Panel from 'Components/Panel';
import PanelDivider from 'Components/PanelDivider';
import Loading from 'Components/ScopedLoader';
import { actions as deployActions } from 'ducks/deploys';
import { actions as deviceActions } from 'ducks/devices';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BalenaDetails from './BalenaDetails';
import Table from './Table';

const Component = props => {
    const { device } = props;

    const balenaStatus = useSelector(state => state.devices.balenaStatus[props.device.id]);
    const deploys = useSelector(state => state.deploys.models);
    const loading = useSelector(state => state.deploys.loading || state.devices.loading);
    const submitting = useSelector(state => state.devices.submitting);

    const syncable = balenaStatus?.manageable || process.env.ENVIRONMENT === 'development' || false;

    const dispatch = useDispatch();
    const { deploy, getBalenaStatus, fetchAll } = ({
        deploy: id => dispatch(deviceActions.deploy(id)),
        getBalenaStatus: id => dispatch(deviceActions.getBalenaStatus(id)),
        fetchAll: (params, meta) => dispatch(deployActions.fetchAll(params, meta))
    });

    useEffect(() => {
        fetchAll({ device_id: device.id });
    }, [device.id]);

    useEffect(() => {
        getBalenaStatus(device.id);
    }, [device.id]);

    if (loading) {
        return (
            <Panel>
                <Loading/>
            </Panel>
        );
    }
    return (
        <>
            <BalenaDetails balenaStatus={balenaStatus} device={device} submitting={submitting}/>
            <PanelDivider/>
            <Table deploy={deploy} deploys={deploys} device={device} submitting={submitting} syncable={syncable}/>
        </>
    );
};

export default Component;
