import EntityManageView from 'Components/EntityManageView';
import { actions } from 'ducks/equipmentAttachments';
import React from 'react';
import { useSelector } from 'react-redux';
import validators from 'utils/forms/validators';

const Component = props => {

    const model = useSelector(state => state.equipmentAttachments.models[state.drawerView.entityId]);
    const submitting = useSelector(state => state.equipmentAttachments.submitting);
    const stateProps = {model, submitting};

    const stateActions = ({
        create: actions.useCreate(),
        update: actions.useUpdate()
    });

    const formSchema = [
        {
            type: 'text',
            label: 'Name\x2a',
            name: 'name',
            validate: [validators.required]
        },
        {
            type: 'textArea',
            label: 'Comment',
            name: 'comment'
        }
    ];

    return (
        <EntityManageView
            formSchema={formSchema}
            formInitialValues={stateProps.model}
            label="Edit Attachment"
            {...stateProps}
            {...stateActions}
            {...props}
        />
    );
};

export default Component;
