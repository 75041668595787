import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';

import Table from './Table';
import Toolbar from './Toolbar';

const TOOLBAR_HEIGHT = '48px';

const TableContainer = styled(Grid)({
    height: `calc(100% - ${TOOLBAR_HEIGHT})`,
});

const Component = ({
                       allChecked,
                       checked,
                       changeSearch,
                       changeSort,
                       columns,
                       fetchData,
                       ids,
                       loaded,
                       loading,
                       models,
                       onRowClick,
                       searching,
                       searchValue,
                       sortBy,
                       sortDirection,
                       toggleAll,
                       toggleOne,
                       TableActions,
                   }) => {
    useEffect(() => {
        if (!loaded) {
            fetchData();
        }
    }, []);

    return (
        <>
            <Grid container size={12}>
                <Toolbar
                    changeSearch={changeSearch}
                    ids={ids}
                    loading={loading}
                    models={models}
                    searching={searching}
                    searchValue={searchValue}
                    TableActions={TableActions}
                />
            </Grid>

            <TableContainer container size={12}>
                <Table
                    allChecked={allChecked}
                    checked={checked}
                    columns={columns}
                    models={models}
                    ids={ids}
                    onRowClick={onRowClick}
                    onSort={changeSort}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    toggleAll={toggleAll}
                    toggleOne={toggleOne}
                />
            </TableContainer>
        </>
    );
};

export default Component;
