import redirect from 'react-router-dom/Redirect';
import pluralize from 'utils/pluralize';
import { useDispatch } from 'react-redux';

const noOp = () => {};

export default TYPE => {
    const PLURAL = pluralize(TYPE);

    const actions = {
        changeSearch: searchValue => ({
            type: `CHANGE_${PLURAL}_SEARCH`,
            payload: searchValue
        }),

        changeSort: ({ sortBy, sortDirection }) => ({
            type: `CHANGE_${PLURAL}_SORT`,
            payload: {
                sortBy,
                sortDirection
            }
        }),

        create: (data, redirect) => ({
            type: `CREATE_${TYPE}`,
            payload: data,
            meta: redirect
        }),

        remove: (id, redirect) => ({
            type: `DELETE_${TYPE}`,
            payload: id,
            meta: redirect || noOp
        }),

        fetchAll: (params, meta) => ({
            type: `FETCH_${PLURAL}`,
            payload: params,
            meta: meta
        }),

        fetchOne: id => ({
            type: `FETCH_${TYPE}`,
            payload: id
        }),

        update: (id, data, redirect) => ({
            type: `UPDATE_${TYPE}`,
            payload: { id, data },
            meta: redirect
        }),

        toggleAll: () => ({
            type: `TOGGLE_ALL_${PLURAL}`
        }),

        toggleOne: id => ({
            type: `TOGGLE_ONE_${TYPE}`,
            payload: id
        })
    };

    const useActions = {
        useChangeSearch: () => {
            const dispatch = useDispatch();
            return (searchValue) => dispatch(actions.changeSearch(searchValue));
        },
        useChangeSort: () => {
            const dispatch = useDispatch();
            return ({ sortBy, sortDirection }) => dispatch(actions.changeSort({ sortBy, sortDirection }));
        },
        useCreate: () => {
            const dispatch = useDispatch();
            return (data, redirect) => dispatch(actions.create(data, redirect));
        },
        useRemove: () => {
            const dispatch = useDispatch();
            return (id, redirect) => dispatch(actions.remove(id, redirect));
        },
        useFetchAll: () => {
            const dispatch = useDispatch();
            return (params, meta) => dispatch(actions.fetchAll(params, meta));
        },
        useFetchOne: () => {
            const dispatch = useDispatch();
            return (id) => dispatch(actions.fetchOne(id));
        },
        useUpdate: () => {
            const dispatch = useDispatch();
            return (id, data, redirect) => dispatch(actions.update(id, data, redirect));
        },
        useToggleAll: () => {
            const dispatch = useDispatch();
            return () => dispatch(actions.toggleAll());
        },
        useToggleOne: () => {
            const dispatch = useDispatch();
            return (id) => dispatch(actions.toggleOne(id));
        }
    };

    return {
        ...actions,
        ...useActions
    };
};
