import Check from 'Components/Check';
import Drawer from 'Components/Drawer';
import Panel from 'Components/Panel';
import Table from 'Components/SimpleTable';
import { actions } from 'ducks/deviceLogs';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unixToDate } from 'utils/date';
import statusToColor from 'utils/statusToColor';
import icons from 'utils/ui/icons';
import Add from './Add';

const statusToComponent = model => {
    const description = model.description.split('\n').map((line, index) => {
        return (
            <React.Fragment key={index}>
                {line}
                <br/>
            </React.Fragment>
        );
    });

    if (model.type === 'status') {
        return (
            <span style={{ color: statusToColor(model.description.toLowerCase().replace('status changed to ', '')) }}>
        {description}
      </span>
        );
    }

    return description;
};

const humanizeDuration = model => {
    if (model.type === 'status' && model.duration) {
        return (
            moment.duration(model.duration, 'seconds').humanize()
        );
    }
};

const statusValidated = model => {
    return (
        <>
            {model.type}
            &nbsp;
            {model.validated && model.type === 'status' ? <Check/> : null}
        </>
    );
};

const columns = [
    { label: 'Type', dataKey: 'type', transform: statusValidated },
    { label: 'Date', dataKey: 'created_at', transform: model => unixToDate(model.created_at) },
    { label: 'Duration', dataKey: 'duration', transform: humanizeDuration },
    { label: 'Description', dataKey: 'description', transform: statusToComponent }
];

const Component = props => {
    const { device } = props;

    const loading = useSelector(state => state.deviceLogs.loading);
    const logs = useSelector(state => state.deviceLogs.data);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.fetchAll({ device_id: device.id }));
        return () => dispatch(actions.clear());
    }, []);

    const [drawer, setOpenDrawer] = useState(null);
    const closeDrawer = () => setOpenDrawer(null);

    let buttons = [
        {
            icon: icons.add,
            onClick: () => setOpenDrawer('addEntry'),
            tooltip: 'Add Log Entry'
        }
    ];

    let DrawerContent;
    if (drawer === 'addEntry') {
        DrawerContent = (
            <Add device={device} onSave={closeDrawer}/>
        );

    }

    return (
        <React.Fragment>
            <Panel buttons={buttons}>
                <Table columns={columns} loading={loading} rows={logs}/>
            </Panel>
            <Drawer onClose={closeDrawer} open={!!drawer}>
                {DrawerContent}
            </Drawer>
        </React.Fragment>
    );
};

export default Component;
