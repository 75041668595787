import React from 'react';
import PropTypes from 'prop-types';
import { TextField, styled } from '@mui/material';

const StyledTextField = styled(TextField)({
    width: '100%',
    '& .MuiInputBase-input': {
        fontSize: '0.65rem',
    },
    '& .MuiFilledInput-root': {
        height: 30,
        paddingBottom: 15,
    },
    '& .MuiInputLabel-root': {
        transform: 'translate(12px, -15px) scale(0.75)',
    }
});

const TextFieldComponent = ({ disabled, input, label, meta, ...rest }) => {
    input.value = input.value.toString();

    return (
        <StyledTextField
            {...rest}
            autoComplete="off"
            disabled={disabled}
            label={label}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            inputProps={input}
            margin="normal"
            variant="filled"
        />
    );
};

TextFieldComponent.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object.isRequired, // Injected by Redux Forms
    label: PropTypes.string,
    meta: PropTypes.object, // Injected by Redux Forms
};

export default TextFieldComponent;
