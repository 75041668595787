import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const Component = props => {
    const { content } = props;

    const StyledTypography = styled(Typography)({
        fontSize: '0.65rem',
        lineHeight: 2.2,
        maxHeight: 150,
        overflow: 'auto'
    });

    return <Grid container>
        {
            content.map(({ label, content, styleLabel }, index) => (
                <React.Fragment key={index}>
                    <Grid size={4}>
                        <StyledTypography component="div" color="textPrimary" style={styleLabel}>
                            {label}
                        </StyledTypography>
                    </Grid>
                    <Grid size={8}>
                        <StyledTypography component="div" color="textPrimary">
                            {content}
                        </StyledTypography>
                    </Grid>
                    <Grid size={12}>
                        <Divider/>
                    </Grid>
                </React.Fragment>
            ))
        }
    </Grid>
};

export default Component;

Component.propTypes = {
    classes: PropTypes.object.isRequired,
    content: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            content: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
                PropTypes.node
            ]),
            styleLabel: PropTypes.object
        })
    ).isRequired
};
