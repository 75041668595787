import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions } from 'ducks/indicators';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';

const Component = props => {
    const { measurement } = props;

    const { indicators, submitting } = useSelector(state => ({
            indicators: state.indicators.models,
            submitting: state.indicators.submitting
        })
    );

    const formSchema = [
        {
            type: 'select',
            label: 'Indicator',
            name: 'indicator_id',
            formData: Object.values(indicators).filter(indicator =>
                !indicator.measurement_id &&
                indicator.building_id === measurement.building_id &&
                indicator.kind_id === measurement.indicator_kind_id
            )
        }
    ];

    const dispatch = useDispatch();
    const onSubmit = values => {
        const data = serializeForm(values, formSchema, measurement);
        dispatch(actions.linkMeasurement(data.indicator_id, measurement.id));
    };

    return (
        <FormViewer label="Add To Indicator">
            <Form
                formSchema={formSchema}
                formInitialValues={measurement}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;

Component.propTypes = {
    measurement: PropTypes.object.isRequired
};
