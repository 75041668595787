import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import Icon from 'Components/Icon';
import icons from 'utils/ui/icons';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    fontSize: 20,
}));

const TableActions = ({ children, loading, fetchData, newEntity }) => {
    return (
        <>
            <>
                {newEntity && (
                    <Tooltip title="New entity">
                        <StyledIconButton onClick={newEntity}>
                            <Icon icon={icons.plus} size="xs" fixedWidth />
                        </StyledIconButton>
                    </Tooltip>
                )}

                <Tooltip title="Refresh data">
          <span>
            <StyledIconButton disabled={loading} onClick={fetchData}>
              <Icon icon={icons.refresh} size="xs" spin={loading} fixedWidth />
            </StyledIconButton>
          </span>
                </Tooltip>
            </>
            {children}
        </>
    );
};

export default TableActions;
