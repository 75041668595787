import Panel from 'Components/Panel';
import Table from 'Components/SimpleTable';
import { actions as logActions } from 'ducks/logs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unixToDate } from 'utils/date';
import LastSync from './LastSync';

const columns = [
    { label: 'Action', dataKey: 'action' },
    { label: 'IP', dataKey: 'ip' },
    { label: 'Method', dataKey: 'method' },
    { label: 'Params', dataKey: 'timestamp' },
    { label: 'Status', dataKey: 'status' },
    { label: 'Headers', dataKey: 'headers', transform: model => JSON.stringify(model.headers) },
    { label: 'Created', dataKey: 'created_at', transform: model => unixToDate(model.created_at) }
];

const Component = props => {
    const { device } = props;

    const accessLogsUnmap = useSelector(state => state.logs.access);
    const loading = useSelector(state => state.logs.loading);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(logActions.startPoolingAccessLogs(device.id));

        return () => dispatch(logActions.stopPoolingAccessLogs(device.id));
    }, []);

    const accessLogs = accessLogsUnmap.map(log => ({ ...log, id: `${log.method}-${log.created_at}-${log.action}` }));

    return (
        <Panel>
            <LastSync loading={loading}/>
            <Table columns={columns} loading={loading} rows={accessLogs}/>
        </Panel>
    );
};


export default Component;
