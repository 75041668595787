import FormViewer from 'Components/FormViewer';
import React from 'react';
import { useSelector } from 'react-redux';
import globals from '~/globals';

const Component = props => {

    const template = useSelector(state => state.templates.models[state.drawerView.entityId]);

    return (
        <FormViewer label="Template Details">
            <a target="_blank" href={`${globals.apiUrl}/installation_templates/${template.id}/report`} rel="noreferrer">Download File</a>
            <br/>
            <pre>{JSON.stringify(template.data, null, 2)}</pre>
        </FormViewer>
    );
};

export default Component;
