import Note from 'Components/Note';
import Panel from 'Components/Panel';
import Loading from 'Components/ScopedLoader';
import Table from 'Components/SimpleTable';
import { actions } from 'ducks/measurements';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unixToDate } from 'utils/date';

const columns = [
    { label: 'Timestamp', dataKey: 'timestamp' },
    { label: 'Value', dataKey: 'value' }
];

const Component = props => {

    const data = useSelector(state => state.measurements.data);
    const loading = useSelector(state => state.measurements.loading);
    const measurement = useSelector(state => state.measurements.models[props.id]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.fetchData(measurement.id));
    }, []);

    if (loading) {
        return (
            <Panel>
                <Loading/>
            </Panel>
        );
    }

    const rows = data.map(row => ({
        id: row.t,
        timestamp: unixToDate(row.t),
        value: row.v
    }));

    return (
        <Panel>
            <Note>Note: Only the last 100 rows will be shown here</Note>
            <Table columns={columns} rows={rows} rowsPerPage={100}/>
        </Panel>
    );
};

export default Component;
