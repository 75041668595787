import React from 'react';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import TabsToolbar from 'Components/TabsToolbar';
import Details from './Details';
import Devices from './Devices';
import Indicators from './Indicators';
import Zones from './Zones';
import { HEADER_HEIGHT, SPACER_MINI, TABS_HEIGHT } from 'utils/ui/constants';

const MainContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.backgrounds.color.main,
    padding: '0px 10px 10px',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
}));

const HeaderContainer = styled(Grid)({
    height: `${TABS_HEIGHT + SPACER_MINI}px`,
    width: '100%',
});

const BottomContainer = styled(Grid)({
    height: `calc(100% - ${TABS_HEIGHT + SPACER_MINI}px)`,
    overflowY: 'auto'
});

const Component = ({ buildingId, section = 'details' }) => {
    let Section;

    switch (section) {
        case 'indicators':
            Section = <Indicators buildingId={buildingId} />;
            break;
        case 'devices':
            Section = <Devices buildingId={buildingId} />;
            break;
        case 'zones':
            Section = <Zones buildingId={buildingId} />;
            break;
        default:
            Section = <Details buildingId={buildingId} />;
    }

    const tabs = [
        { id: 'details', text: 'Details', url: `/buildings/${buildingId}/details` },
        { id: 'devices', text: 'Devices', url: `/buildings/${buildingId}/devices` },
        { id: 'indicators', text: 'Indicators', url: `/buildings/${buildingId}/indicators` },
        { id: 'zones', text: 'Zones', url: `/buildings/${buildingId}/zones` }
    ];

    return (
        <MainContainer container>
            <HeaderContainer  size={12}>
                <TabsToolbar tabs={tabs} currentTab={section} />
            </HeaderContainer>
            <BottomContainer size={12}>
                {Section}
            </BottomContainer>
        </MainContainer>
    );
};

export default Component;
