import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as drawerViewActions } from 'ducks/drawerView';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';

const Component = props => {
    const { extraData, formSchema, formInitialValues, label, submitting } = props;
    const { create, update } = props;

    const { entityId, submitType } = useSelector(state => ({
            entityId: state.drawerView.entityId,
            submitType: state.drawerView.context
        })
    );

    const dispatch = useDispatch();
    const onBack = () => dispatch(drawerViewActions.previousState());

    const onSubmit = values => {
        if (submitType === 'edit') {
            let data = serializeForm(values, formSchema, formInitialValues);
            update(entityId, data, onBack);
        } else if (submitType === 'new') {
            let data = { ...serializeForm(values, formSchema), ...extraData };
            create(data, onBack);
        }
    };

    return (
        <FormViewer label={label} onBack={onBack}>
            <Form
                formSchema={formSchema}
                formInitialValues={formInitialValues}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;

Component.propTypes = {
    create: PropTypes.func,
    extraData: PropTypes.object,
    formSchema: PropTypes.array,
    label: PropTypes.string,
    formInitialValues: PropTypes.object,
    submitting: PropTypes.bool,
    update: PropTypes.func
};
