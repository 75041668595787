import React from 'react';
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Icon from 'Components/Icon';
import { actions } from 'ducks/buildings';
import { useSelector } from 'react-redux';
import icons from 'utils/ui/icons';
import history from '~/history';

const RootMain = styled('div')({
    padding: 10,
    width: '100vw',
});

const StyledPaper = styled(Paper)({
    borderRadius: 0,
    padding: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
});

const InputDiv = styled('div')({
    padding: 0,
    width: 'calc(100% - 48px)',
});

const StyledInputBase = styled(InputBase)({
    fontSize: 12,
    padding: '10px 15px',
    width: '100%',
});

const Component = () => {
    const searchValue = useSelector(state => state.buildings.searchValue);
    const changeSearch = actions.useChangeSearch();

    return (
        <RootMain>
            <StyledPaper>
                <InputDiv>
                    <StyledInputBase
                        placeholder="Search by building name, organization name or current status"
                        onChange={event => changeSearch(event.target.value)}
                        value={searchValue}
                    />
                </InputDiv>
                <IconButton onClick={() => history.push('/buildings')}>
                    <Icon icon={icons.buildings} size="xs" fixedWidth/>
                </IconButton>
            </StyledPaper>
        </RootMain>
    );
};

export default Component;
