import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TABS_HEIGHT } from 'utils/ui/constants.js';
import { styled } from '@mui/material/styles';

// Styled Components
const StyledTab = styled(Tab)({
    fontSize: '0.65rem',
    height: TABS_HEIGHT,
    minHeight: TABS_HEIGHT,
    minWidth: 72,
});

const StyledTabs = styled(Tabs)({
    height: TABS_HEIGHT,
    minHeight: TABS_HEIGHT
});

// Scroll to a section smoothly
const scrollTo = id => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
};

const Component = ({ currentTab, elevation, tabs }) => {
    let selectedTab, onChange;

    if (currentTab) {
        selectedTab = tabs.findIndex(tab => tab.id === currentTab);
        if (selectedTab === -1) selectedTab = 0;
    } else {
        const [stateSelectedTab, setTab] = useState(0);
        selectedTab = stateSelectedTab;
        onChange = (_, index) => {
            setTab(index);
            scrollTo(tabs[index].id);
        };
    }

    const TabsList = tabs.map(tab =>
        tab.url ? (
            <StyledTab key={tab.id} label={tab.text} component={Link} to={tab.url} />
        ) : (
            <StyledTab key={tab.id} label={tab.text} />
        )
    );

    return (
        <Paper square elevation={elevation}>
            <StyledTabs
                indicatorColor="primary"
                textColor="primary"
                onChange={onChange}
                value={selectedTab}
            >
                {TabsList}
            </StyledTabs>
        </Paper>
    );
};

// PropTypes
Component.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            url: PropTypes.string // Optional URL
        })
    ).isRequired,
    elevation: PropTypes.number,
    currentTab: PropTypes.string
};

export default Component;
