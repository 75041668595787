import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions as templateActions } from 'ducks/templates';
import React from 'react';
import { useSelector } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';
import history from '~/history';

const Component = props => {
    const { closeDrawer } = props;

    const device = useSelector(state => state.devices.models[props.id]);
    const submitting = useSelector(state => state.templates.submitting);

    let formSchema = [
        {
            type: 'text',
            label: 'Name\x2a',
            name: 'name',
            validate: [validators.required]
        }
    ];

    const create = templateActions.useCreate();
    const onSubmit = values => {
        const data = serializeForm(values, formSchema);
        data.wise_box_id = device.id;

        create(data, () => {
            closeDrawer();
            history.push('/templates');
        });
    };

    return (
        <FormViewer label="Create Template">
            <Form
                formSchema={formSchema}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;
