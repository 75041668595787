import React from 'react';
import { AppBar, IconButton, Toolbar, styled, alpha } from '@mui/material';
import { useDispatch } from 'react-redux';
import { openSidebar } from 'ducks/ui';
import { HEADER_HEIGHT } from 'utils/ui/constants.js';
import Buttons from './Buttons';
import Sidebar from './Sidebar';
import logoImage from 'images/core/logo.png';
import textImage from 'images/core/text.png';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    padding: 0,
    zIndex: theme.zIndex.drawer + 1,
    height: HEADER_HEIGHT // Strict height of 64px
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    paddingLeft: 0,
    minHeight: HEADER_HEIGHT, // Ensure toolbar maintains 64px height
    height: HEADER_HEIGHT
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.white,
    marginRight: 0,
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.05),
        borderRadius: 0
    }
}));

const LogoImage = styled('img')({
    maxHeight: 40,
    padding: 4
});

const TextImage = styled('img')({
    maxHeight: 40,
    padding: 4
});

const Grow = styled('div')({
    flexGrow: 1
});

const Component = ({ sections }) => {
    const dispatch = useDispatch();
    const openBar = () => dispatch(openSidebar());

    return (
        <>
            <StyledAppBar position="fixed">
                <StyledToolbar variant="dense">
                    <StyledIconButton onClick={openBar} onMouseEnter={openBar}>
                        <LogoImage alt="-" src={logoImage} />
                    </StyledIconButton>
                    <TextImage alt="WiseMetering" src={textImage} />
                    <Grow />
                    <Buttons />
                </StyledToolbar>
            </StyledAppBar>
            <Sidebar sections={sections} />
        </>
    );
};

export default Component;
