import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import ExpandedPanel from 'Components/ExpandedPanel';
import { actions } from 'ducks/readings';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Details from './Details';
import Measurements from './Measurements';
import Toolbar from './Toolbar';
import { HEADER_HEIGHT, SPACER_MINI, TABS_HEIGHT } from 'utils/ui/constants';

const MainContainer = styled(Grid)(({ theme }) => ({
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    backgroundColor: theme.palette.backgrounds.color.main,
    padding: '0px 10px 0px'
}));

const HeaderContainer = styled(Grid)(() => ({
    height: `${TABS_HEIGHT + SPACER_MINI}px`
}));

const BottomContainer = styled(Grid)(() => ({
    height: `calc(100% - ${TABS_HEIGHT + SPACER_MINI + SPACER_MINI}px)`,
    paddingBottom: 5,
    overflowY: 'auto'
}));

const Component = ({ disableButtons = false, id }) => {
    const reading = useSelector(state => state.readings.models[id || state.drawerView.entityId]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.fetchOne(reading.id));
    }, [reading.id, dispatch]);

    return (
        <MainContainer container>
            <HeaderContainer item size={12}>
                <Toolbar disableButtons={disableButtons} reading={reading} />
            </HeaderContainer>
            <BottomContainer item size={12}>
                <ExpandedPanel id="properties" header="Details">
                    <Details reading={reading} />
                </ExpandedPanel>
                <ExpandedPanel id="measurements" header="Measurements">
                    <Measurements measurements={reading.measurements} />
                </ExpandedPanel>
            </BottomContainer>
        </MainContainer>
    );
};

export default Component;
