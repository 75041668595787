import Panel from 'Components/Panel';
import { actions as logActions } from 'ducks/logs';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Table from './Table';

const Component = props => {
    const { device } = props;

    const [realtime, setRealtime] = React.useState(true);

    const dispatch = useDispatch();
    const startPoolingRealtimeData = id => dispatch(logActions.startPoolingRealtimeData(id));
    const stopPoolingRealtimeData = id => dispatch(logActions.stopPoolingRealtimeData(id));

    useEffect(() => {
        if (realtime) {
            startPoolingRealtimeData(device.id);
        }
        return () => stopPoolingRealtimeData(device.id);
    }, []);

    const handleRealtimeChange = newRealtimeValue => {
        newRealtimeValue ? startPoolingRealtimeData(device.id) : stopPoolingRealtimeData(device.id);

        setRealtime(newRealtimeValue);
    };

    return (
        <React.Fragment>
            <Panel realtime={realtime} setRealtime={handleRealtimeChange}>
                <Table device={device} realtime={realtime}/>
            </Panel>
        </React.Fragment>
    );
};

export default Component;
