import { useState, useEffect } from 'react';
import { LoginCard } from '~/ComponentsTW/layout/login-card';
import { Form, FormInput, FormLabel, Button, PasswordInput, StandaloneLink } from '~/ComponentsTW/wm';
import { apiRequest } from 'utils/request';
import * as React from 'react';

export function LoginForm({ onOtpRequest, onAlert, onForgotPassword }) {
    const
            [email, setEmail] = useState(''),
            [formFilled, setFormFilled] = useState(false),
            [loading, setLoading] = useState(false),
            [password, setPassword] = useState('');

    useEffect(() => {
        const formFilled = password !== '' && email !== '';

        setFormFilled(formFilled);
    }, [email, password]);

    const handleForgotPassword = () => {
        onForgotPassword(email);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const data = {
            email,
            password
        };

        try {
            const response = await apiRequest('/login', 'POST', data);

            if (response.status === 200) {
                onOtpRequest(email, password);
            }
        } catch(err) {
            const errorMessage = err.response?.data?.error || 'An error occurred';

            onAlert(errorMessage, 'destructive', 'Failed')
            setPassword('');
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    return (
            <LoginCard>
                <Form>
                    <FormLabel htmlFor="email">email</FormLabel>
                    <FormInput
                            id="email"
                            type="email"
                            placeholder="m@example.com"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                    />
                    <FormLabel htmlFor="password">password</FormLabel>
                    <PasswordInput
                            id="password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button type="submit" loading={loading} onClick={handleSubmit} className="tw-w-full" variant="secondary" disabled={!formFilled}>
                        Login
                    </Button>
                </Form>
                <div className="tw-flex tw-justify-between tw-gap-2">
                    <StandaloneLink onAction={handleForgotPassword} text="Forgot password?" />
                    <StandaloneLink text="Contact us" href="mailto:support@wisemetering.com"/>
                </div>
            </LoginCard>
    );
}
