import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    AdvancedMarker,
    InfoWindow,
    Pin,
    useAdvancedMarkerRef
} from '@vis.gl/react-google-maps';
import { styled } from '@mui/material/styles';
import theme from '~/theme.js';

const InfoBoxContent = styled('div')(({ theme }) => ({
    background: 'grey',
    padding: '1em',
    border: '1px solid grey',
    borderRadius: '8px',
    opacity: 0.9
}));

const Component = props => {
    const { tooltip, position, tooltipMutex, tooltipID, status } = props;
    const { handleSetTooltipMutex, onClick } = props;

    // `markerRef` and `marker` are needed to establish the connection between
    // the marker and infowindow (if you're using the Marker component, you
    // can use the `useMarkerRef` hook instead).
    const [markerRef, marker] = useAdvancedMarkerRef();

    const [infoWindowShown, setInfoWindowShown] = useState(false);


    // boxId: PropTypes.number -> Id for the tooltip
    // tooltipMutex: PropTypes.number -> current Id for the tooltip open
    // handleSetTooltipMutex: PropTypes.func, -> handler that controls parent state
    //
    // Allow to control the Tooltip rendering from the outside, only one tooltip
    // should be rendered
    useEffect(() => {
        // Close this InfoWindow if another one was opened
        if (tooltipMutex !== tooltipID) {
            setInfoWindowShown(false);
        }
    }, [tooltipMutex, tooltipID]);

    const handleMouseEnter = useCallback(() => {
        if (tooltipMutex === 0) {
            handleSetTooltipMutex(tooltipID);
            setInfoWindowShown(true);
        } else if (tooltipMutex !== tooltipID) {
            handleSetTooltipMutex(0);
        }
    }, [tooltipMutex, tooltipID, handleSetTooltipMutex]);

    const handleMouseLeave = useCallback(() => {
        if (tooltipMutex === tooltipID) {
            setInfoWindowShown(false);
            handleSetTooltipMutex(0);
        }
    }, [tooltipMutex, tooltipID, handleSetTooltipMutex]);

    const InfoBox = () => {
        if (infoWindowShown) {
            return (
                <InfoWindow
                    headerDisabled={true}
                    anchor={marker}
                >
                    <InfoBoxContent>
                        <div>{tooltip}</div>
                    </InfoBoxContent>
                </InfoWindow>
            );
        }
    };

    return (
        <>
            <AdvancedMarker
                ref={markerRef}
                onClick={onClick}
                position={position}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Pin background={theme.palette.pins[status]} glyphColor={theme.palette.pins[status]} borderColor="#000" scale={0.6}/>
            </AdvancedMarker>
            <InfoBox/>
        </>
    );
};

Component.defaultProps = {
    tooltip: 'Tooltip information',
    position: { lat: 40.418061, lng: -3.696032 },
    status: 'ok'
};

Component.propTypes = {
    tooltip: PropTypes.element,
    tooltipID: PropTypes.number,
    tooltipMutex: PropTypes.number,
    handleSetTooltipMutex: PropTypes.func,
    position: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired
    }).isRequired,
    status: PropTypes.string,
    classesOverride: PropTypes.shape({
        infoBoxContent: PropTypes.object
    }),
    onClick: PropTypes.func
};

export default Component;
