import Form from 'Components/Forms/FormGenerator';
import FormViewer from 'Components/FormViewer';
import { actions } from 'ducks/installationPictures';
import React from 'react';
import { useSelector } from 'react-redux';
import { serializeForm } from 'utils/forms/utils';
import validators from 'utils/forms/validators';

const Component = props => {
    const { deviceId } = props;
    const { onSave } = props;

    const submitting = useSelector(state => state.installationPictures.submitting);

    const formSchema = [
        {
            accept: 'image/jpg,image/png',
            label: 'File\x2a (max 2Mb)',
            name: 'file',
            type: 'file',
            validate: [validators.required]
        },
        {
            label: 'Comment',
            name: 'comment',
            rows: 4,
            type: 'textArea'
        }
    ];

    const create = actions.useCreate();
    const onSubmit = values => {
        const data = {
            ...serializeForm(values, formSchema),
            ...{ device_id: deviceId }
        };
        create(data, onSave);
    };

    return (
        <FormViewer label="Add Installation Picture">
            <Form
                formSchema={formSchema}
                isSubmitting={submitting}
                onSubmit={onSubmit}
            />
        </FormViewer>
    );
};

export default Component;
